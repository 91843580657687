.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loading-spinner {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1200;
  .loader {
    left: 50%;
    top: 130px;
    position: absolute !important;
    margin: -66px 0px 0px -66px !important;
  }
}

.loading-spinner-redirect {
  background-color: rgba(0, 0, 0, 0.2);
  // position: absolute;
  width: 100%;
  height: 500px;
  z-index: 1200;
  margin-top: -2%;
  .loader {
    left: 50%;
    top: 30%;
    position: absolute !important;
    margin: -60px 0px 0px -66px !important;
  }
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
