@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&amp;display=swap');
@import './Variables.scss';
@import './Modules/mixins';
// @font-face{
//   font-family:'FontAwesome';
//   src:url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.eot?v=4.7.0');
//   src:url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0./fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
//   url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0./fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
//   url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
//   url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
//   url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');font-weight:normal;font-style:normal;
// }
@font-face {
  font-family: "FontAwesomeIcon";
  src: url("./fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("./fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), 
  url("./fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"),
   url("./fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"),
    url("./fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"),
     url("./fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  font-family: 'Open Sans', sans-serif, Arial;
}
html,
body {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.025em;
}
div,
main,
section,
article {
  max-width: 100%;
}
/*--Variables Starts Here--*/
$PrimaryColor: #274463;
$SecondaryColor: #0047BA;
$ErrorColor: #A81F00;
$SuccessColor: #00B4A0;
$fontFamily: 'Open Sans', sans-serif !important;
$disabledColor: #888888;
$iconColor: #274463;

/*--Mixins Starts Here--*/
@mixin border-radius($val) {
  border-radius: $val;
  -webkit-border-radius: $val;
  -ms-border-radius: $val;
}
@mixin boxShadow($val) {
  box-shadow: $val;
  -webkit-box-shadow: $val;
  -ms-box-shadow: $val;
}

/*--Buttons CSS--*/
.mfComponent {
.btn {
  color: white !important;
  font-weight: 600 !important;
  font-size: 0.85rem !important;
  padding: 8px 15px !important;
  border-width: 1px !important;
  @include border-radius(5px !important);
  @include boxShadow(6px 3px 12px rgba(39,68,99,.3)!important); 
  text-transform: uppercase;
  position: relative;
  text-decoration: none !important;
  .fa {
    font-size: 12px;
    margin-right: 5px;
  }
}
.button-container {
  margin-bottom: 10px;
}
.btn:before, .btn-add-select:after {
  font-family: "FontAwesomeIcon";
  font-size: 0.83rem;
  font-weight: 400;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
}
.btn-save:before, .btn-update:before {
  content: "\f00c";
}
.btn-add:before {
  content: "\f067";
}
.btn-add-select {
  padding-right: 30px!important;
}
.btn-add-select:after {
  content: "\f107";
  font-size: 1.2rem;
}
.btn-menu-child {
  border-bottom: 1px solid #000000;
  padding: 0 5px 0 5px!important;
}
.btn-search:before {
  content: "\f002";
}
.btn-delete:before {
  content: "\f1f8";
  font-size: 0.9rem;
}
.btn-reset:before {
  content: "\f0e2";
}
.btn-help:before {
  content: "\f059";
  font-size: 0.9rem;
}
.btn-print:before {
  content: "\f02f";
}
.btn-refresh-upd:before {
  content: "\f021";
}
.btn-notes:before {
  content: "\f15c";
}
.btn-view:before {
  content: "\f06e";
}
.btn-validate:before {
  content: "\f058";
}
.btn-filter:before {
  content: "\f0b0";
  font-size: 1rem !important;
}
.btn-copy:before {
  content: "\f24d";
}
.btn-audit:before {
  content: "\f274";
  font-size: 0.9rem;
  font-weight: 600;
}
.btn-ic {
    padding: 8px 15px 8px 30px !important;
}
.btn-icon-only{ 
  padding: 4px 15px !important; 
  .fa{
    font-size: 20px;
    margin-right: 0;
  }
}
.btn-primary, .btn-search, .btn-print, .btn-cancel, .btn-validate, .btn-copy, .btn-refresh-upd, .btn-filter {
  background-color: $PrimaryColor !important;
  border-color: $PrimaryColor !important;
  &:hover, &:focus {
      background-color: #2f5b8a !important;
    }
}
.btn-success, .btn-save, .btn-add, .btn-update {
    background-color: #006e62 !important;
    border-color: #005A64 !important;
    &:hover, &:focus {
      background-color: #005A64 !important;
    }
}

.btn-secondary, .btn-help {
    background-color: $SecondaryColor !important;
    border-color: $SecondaryColor !important;
    &:hover, &:focus {
      background-color: #0a50c1 !important;
    }
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff!important;
  background-color: #CACDCB!important;
  border-color: #CACDCB!important;
}
.btn-transparent, .btn-delete, .btn-view, .btn-notes, .btn-reset, .btn-audit, .btn-filter-transparent {
    background-color: #fff !important;
    border:solid 1px $PrimaryColor !important;
    color: $PrimaryColor !important;
    &:hover, &:focus {
      background-color: #f8f8f8 !important;
    }
}
.dif, .dis-flex {
  display: flex !important;
}
.flex-block, .form-inner-block {
    display: flex;
    width: 100%;
}
.hide-tfoot tfoot, .hide-elm, .d-none,
.hide-th-checkbox table tr th .MuiCheckbox-root {
  display: none;
}
.mute-elm, .fadeElm, .hide-lbl label  {
  opacity: 0 !important;
}
.disable-theme {
  background: #f4f4f4 !important;
  border-color: #eee !important;
}
.Mui-disabled .MuiSvgIcon-root, .Mui-disabled .MuiTypography-root,
table tr td .MuiIconButton-root.Mui-disabled .MuiSvgIcon-root {
  color: #888 !important;
  background-color: transparent !important;
}

.MuiExpansionPanel-root.collapsable-panel {
  overflow: hidden;
  width: 100% !important; 
}

.CustomExpansion-panel {
   .MuiPaper-elevation1 {
    border: none;
    box-shadow: none;
  }
  .MuiButtonBase-root + .MuiCollapse-container {
  max-height:none;
  overflow: unset;
  border: solid 1px #d6d6d6;
  @include border-radius(0px 0px 4px 4px);
}
}
.mui-custom-form {
   margin: 12px 16px;
  .Mui-error,
  .Mui-required, .MuiFormLabel-asterisk {
    color: $ErrorColor !important;
  }
  .Mui-disabled {
  &.MuiInputBase-root {
    @extend .disable-theme;
    pointer-events: none;
  }
  }
  .MuiFormControl-root {
    width: 100% !important;
    max-width: calc(100% - 2px) !important;
  }
  .MuiFormLabel-root, .MuiCardHeader-root .MuiTypography-h5 {
	  font-size: 1.22em !important;
    white-space: nowrap;
    color: $PrimaryColor !important;
    font-weight: 700;
    letter-spacing: 0.045em;
    -webkit-font-smoothing: antialiased;
    text-transform: capitalize;
  }
  .MuiInput-root {
    margin-top: 23px;
    border: .8px solid #707070;
    @include border-radius(5px);
    padding: 2px 12px;
    height: 38px;
    &:after, &:before {
      border: none !important 
    }
    .MuiIconButton-root {
      padding: 10px 5px;
      &:focus {
        outline: none;
      }
    }
  }
  .MuiInput-root:hover, .MuiInput-root:focus {
    border-color: $PrimaryColor;
  }
  .MuiInput-input {
    color: #000;
    font-size: 14px;
    height: 1.1875em;
    box-sizing: content-box;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .MuiInput-inputMultiline {
    min-height: 16px !important;
    max-height: 65px !important;
    padding: 6px 0 7px;
    overflow: auto !important;
    resize: vertical !important;
  }
  .Mui-disabled {
    .MuiInput-input {
      color: #545955;
    }
    &::before {
      border-bottom-width: 0px;
    }
  }
  .MuiFormLabel-root.small-label {
    font-size: 14px !important;
    margin-bottom: 12px;
    span {
      color: $ErrorColor;
    }
  }
  legend {
    width: 60px;
  }
  .input-text {
    color: #000;
    font-size: 16px;
  }
  .sub-radio {
    margin-top: 10px;
    font-size: 13px;
    overflow: hidden;
	  label {
      margin: 0px;
    }
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
    .MuiCheckbox-root {
      padding: 0px 4px 0px 0px;
      position: relative;
    }
	.Mui-checked {
      .MuiSvgIcon-root {
        color: $PrimaryColor;
      }
      &:hover {
        background-color: rgba(39, 68, 99, 0.08);
      }
    }
    .MuiFormControlLabel-label {
      font-size: 13px;
      font-weight: 400;
      padding-top: 2px;
      color: #545955;
      position: relative;
      left: -3px;
    }
  }
  .set-sub-radio {
    position: relative;
    left: -10px;
  }
  .MuiSelect-select {
    margin-top: 3px;
  }

  .Table-select {
    width: 140px !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  @media (max-width: 591px) {
    width: 100%;
    .MuiTextField-root,
    .MuiInput-input {
      width: 100% !important;
    }
  }
}

.custom-input-select{
  .MuiInputLabel-root{
    font-size: 0.90em!important;
    margin: 4px 0 6px;
  }
  .MuiInput-root{
    margin-top: 0!important;
    width: 100%!important;
    padding-left: 0;;
  }
  .required-label{
    color: $ErrorColor !important;
    margin-right: 0;
    padding-right: 1px;
  }
}

.MuiNativeSelect-select{
  padding-left: 10px!important;
}
.MuiSvgIcon-root.custom-more-icon{
  color: red !important;
}
.collapsable-panel {
  .MuiExpansionPanelSummary-root {
    padding: 0 16px !important;
    @include border-radius(4px);
    background-color: $PrimaryColor;
    min-height: 40px;
    .MuiCollapse-wrapper & {
      background-color: #f4f4f4;
      border: solid 1px #d6d6d6;
    }
    .MuiTypography-root {
      color: #ffffff !important;
      font-weight: 500;
      text-transform: capitalize;
      font-size: 1.13rem;
      .MuiCollapse-wrapper & {
        color: $PrimaryColor !important;
      }
    }
    .MuiSvgIcon-root {
      color: #ffffff !important;
      .MuiCollapse-wrapper & {
        color: $PrimaryColor !important;
      }
    }
  }
  .MuiCollapse-wrapper &.Mui-expanded
    > .MuiExpansionPanelSummary-root { 
      border-bottom: 0 none;
  }
  .panel-header {
    padding: 9px 15px !important;
    background-color: $PrimaryColor;
    min-height: auto !important;
    @include border-radius(4px 4px 0px 0px);
    .MuiExpansionPanelSummary-content {
      margin: 0px;
    }
    .MuiIconButton-root {
      padding: 0px !important;
      color: #fff;
    }
  }
  .MuiExpansionPanel-root {
    //border: 1px solid #aaafb9 !important;
    @include border-radius(4px 4px 0px 0px);
    border-top-width: 0px !important;
    margin-bottom: 10px !important;
    &:before {
      display: none;
    }
  }
  .MuiExpansionPanelDetails-root {
    display: block !important;
    & .collapsable-panel:first-child {
      margin-top: 10px;
    }
    .CustomExpansion-panel & {
      padding:5px 15px 15px !important;
      & .collapsable-panel:last-child {
        margin-bottom: 0 !important;
      }
    }
    &.CustomExpansion-panel {
      padding-top: 0 !important
    }
    .table-wrapper {
      margin: 0;
    }
  }
  .MuiCollapse-wrapper & {
    .MuiExpansionPanelDetails-root {
    > .form-wrapper {
      margin: 0 -5px !important;
      padding: 6px 0 0 !important;
      max-width: none;
    }
  }
  }
}
.custom-summary{
  background-color: #f4f4f4 !important;
  border: solid 1px #d6d6d6 !important;
  .MuiExpansionPanelSummary-content .MuiTypography-root {
    color: $PrimaryColor !important;
  }
  .MuiButtonBase-root .MuiIconButton-label .MuiSvgIcon-root {
    color: $PrimaryColor !important;
  }
  .Mui-expanded
    > .MuiExpansionPanelSummary-root { 
      border-bottom: 0 none;
  }
  .MuiExpansionPanelDetails-root {
    > .form-wrapper {
      margin: 0 -5px !important;
      padding: 6px 0 0 !important;
      max-width: none;
    }
  }
}

.MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 40px !important;
    @include border-radius(4px 4px 0px 0px);
}
.MuiExpansionPanelSummary-content {
  &, .Mui-expanded {
    margin: 8px 0 !important;
  }
}
.form-wrap-inner, .tbody-space, .container-space {
  padding: 0 16px 1rem !important;
}
.tab-holder { padding: 0 !important }
.tab-body, .tab-body-bordered {
  color: #000;
  background-color: #fff;
  margin-top: 0.35em;
  @include border-radius(5px);
}
.tab-body-bordered {
  border: solid 1px #ececec;
  @include boxShadow(0 2px 10px rgba(22,38,56,.04));
}
.tab-header {
  color: #ffffff !important;
  padding: 8px 0 5px;
  &:after {
      content: "";
      clear: both;
      display: block;
  }
  .tab-heading {
    color: #000000;
    padding: 10px 0 5px;
    font-weight: 600;
    font-size: 1.135em;
    margin-bottom: 0px;
    text-transform: none;
    line-height: 1.4;
  }
  .th-btnGroup {
    .btn {
      margin: 3px 0 0 10px;
    }
  }
  .float-right {
    .btn {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }
}
.btn-ic-only {
  padding: 15px 20px 15px 23px !important;
}
.btn-ic-only-sm {
  padding: 7px 6px 7px 23px !important;
}
.btn-icon {
  padding: 8px 10px 8px 30px !important;
}
.btn-ic,
.btn-icon {
  [class^='fa-'] {
    display: none;
  }
}
.form-wrapper {
  padding: 8px 5px;
  .mui-custom-form, .custom-form-wrapp-inner {
    margin: 8px 10px !important;
    width: calc(25% - 20px);
    &.field-md {
      width: calc(50% - 20px) !important;
      max-width: calc(50% - 20px) !important;
      min-width: calc(50% - 20px) !important;
    }
    &.field-lg {
      width: calc(75% - 20px) !important;
      max-width: calc(75% - 20px) !important;
    }
    &.field-xl {
      width: calc(100% - 20px) !important;
      max-width: calc(100% - 20px) !important;
    }
    &.mb-35 {
      margin-bottom: 35px !important;
    }
    @media (max-width: 1024px) {
      &.w-100 {
        width: 100% !important;
      }
      &.md-field-md, &.md-field-lg { 
        width: calc(66.66% - 20px) !important;
        max-width: calc(66.66% - 20px) !important;
        min-width: calc(66.66% - 20px) !important;
        -ms-flex: 0 0 calc(66.66% - 20px) !important;
        flex: 0 0 calc(66.66% - 20px) !important;
      }
      &.md-field-xl {
        width: calc(100% - 20px) !important;
        max-width: calc(100% - 20px) !important;
        -ms-flex: 0 0 calc(100% - 20px) !important;
        flex: 0 0 calc(100% - 20px) !important;
      }
    }
    .clm-inquiry-tabData & {
      @media (max-width: 1200px) {
        width: calc(33.33% - 20px) !important;
        &.lg-field-xl {
          width: calc(100% - 20px) !important;
          max-width: calc(100% - 20px) !important;
          -ms-flex: 0 0 calc(100% - 20px) !important;
          flex: 0 0 calc(100% - 20px) !important;
        }
      }
      @media (max-width: 1024px) {
        width: calc(50% - 20px) !important;
      }
    }
  }
  .mui-custom-form.inner-form-fields {
    margin: 0px !important;
  }
  .custom-form-wrapp-inner {
      margin-top: 0 !important;
    .mui-custom-form {
      margin-left: 0 !important;
      width: calc(49.5% - 10px) !important;
    }
    &.flex-inner {
      display: flex;
      -webkit-display: flex;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      .mui-custom-form {
        flex: 0 0 47%;
        -webkit-flex: 0 0 47%;
        width: 47% !important;
        margin-right: 0 !important;
      }
    }
  }
  .changePswd {
    opacity: 0;
  }
  .mui-custom-form {
    .MuiInputBase-adornedEnd {
      .MuiInputBase-input {
        height: 1.38em;
      }
    }
    .mui-custom-check {
      margin: 2em 0 0;
      .PrivateSwitchBase-root-264, .PrivateSwitchBase-root-349, .PrivateSwitchBase-root-301, .PrivateSwitchBase-root-343 {
        padding: 0 9px;
      }
    }
    label.mui-custom-check {
      .PrivateSwitchBase-root-264 {
        margin-left: -9px;
      }
    }
    .MuiFormGroup-row {
      .MuiFormControlLabel-root {
        margin-bottom: 0;
        .PrivateSwitchBase-root-349, .PrivateSwitchBase-root-301 {
        padding: 0px 5px;
        }
      }
    }
  }
  &.form-3-column {
    .mui-custom-form, .custom-form-wrapp-inner {
      width: calc(33.333% - 20px) !important;
    }
    .custom-form-wrapp-inner {
    .mui-custom-form {
      width: calc(49.5% - 20px) !important;
      @media (max-width: 768px) {
        width: calc(50% - 10px) !important;
      }
    }
  }
  }
  .set-form-wrapper & {
      margin: 0 -10px;
      max-width: none;
  }
  .flex-block {
  -webkit-flex-wrap: wrap;
  -ms-flex-flow: row wrap;
  flex-wrap: wrap;
  }
}
.disabled-form {
  textarea, input {
  @extend .disable-theme;
  @include border-radius(5px);
  padding: 6px;
}
}
.makeStyles-root-1,
.wid-100,
.fm-inline-btn {
  width: 100% !important;
}
.form-wrapper .input-md.wid-100 {
  &,
  .clm-inquiry-tabData & {
    @media (max-width: 1024px) {
      width: 100% !important;
    }
  }
}
.table-wrapper {
  width: 100% !important;
  overflow-x: auto !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  // margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;
  @include border-radius(4px 4px 0 0);
}
.customDataTable {
  //min-width: 750px;
  min-height: 150px;
  .sa-exception-tbl & {
    min-height: 100px;
  }
  thead {
    th {
      font-size: 0.9655rem !important;
      .MuiSvgIcon-root {
        opacity: 0;
        margin: 0;
      }
      &:hover {
        .MuiSvgIcon-root {
          opacity: 1;
        }
      }
      .MuiCheckbox-root {
        .MuiSvgIcon-root {
          color: #fff !important;
          opacity: 1;
        }
      }
    }
  }
  .MuiCheckbox-root {
    padding: 0px;
  }
  .MuiTableCell-root,
  .MuiTableRow-footer {
    padding: 5px 8px;
  }
  .MuiTableHead-root {
    background-color: $PrimaryColor;
    .MuiTableSortLabel-root {
      color: #fff;
      font-weight: 600;
      white-space: nowrap;
      &.MuiTableSortLabel-active {
      .MuiSvgIcon-root {
        color: #fff !important;
      }
    }
    }
  }
  .table-pagination {
    width: 35px;
    min-width: 35px;
    max-width: 35px;
    span {
      width: 25px;
      height: 25px;
      display: inline-block;
      @include border-radius(50%);
      background-color: #545955;
      text-align: center;
      color: #fff;
      line-height: 25px;
    }

  }
  .MuiTableCell-paddingCheckbox{
    width: 35px !important;
    min-width: 35px !important;
    max-width: 35px !important;
    padding: 5px 2px 5px 8px !important;
  }
  .MuiTableFooter-root {
    .MuiIconButton-root {
      padding: 5px 2px;
    }
  }
  .MuiTablePagination-toolbar {
    min-height: 40px !important;
    min-width: 345px;
  }
  .MuiTableFooter-root .MuiIconButton-root:focus {
    outline: none;
    background-color: #DADDDC !important;
  }
}
.desc-truncate{
  word-wrap:break-word;
}
.para-truncate table tr td.MuiTableCell-root{
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-wrapper .flex-block {
  flex-wrap: nowrap !important;
}
.ligin-form {
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 98%;
}
.nameInitialBadge{
  @include border-radius(50%);
  width: 33px;
  height: 33px;
  text-align: center;
  display: inline-block;
  line-height: 33px;
  color:white;
  margin-right: 1em;
  margin-bottom:1em;
  position: relative;
  top: 5px;
  font-weight: 600;
}
.MuiTableRow-root{
  &:nth-child(1n+0){
    .nameInitialBadge{
        background-color: #00A89D;
    }
  }
  &:nth-child(2n+0){
    .nameInitialBadge{
        background-color: #5797FF;
    }
  }
  &:nth-child(3n+0){
    .nameInitialBadge{
        background-color: #663A36;
    }
  }
  &:nth-child(4n+0){
    .nameInitialBadge{
        background-color: #AAAFB9;
    }
  }
  &:nth-child(5n+0){
    .nameInitialBadge{
        background-color: #F59B8F;
    }
  }
  &:nth-child(6n+0){
    .nameInitialBadge{
        background-color: #B57fEB;
    }
  }
  &:nth-child(7n+0){
    .nameInitialBadge{
        background-color: #ff8700;
    }
  }
  &:nth-child(8n+0){
    .nameInitialBadge{
        background-color: #4FBCB5;
    }
  }
  &:nth-child(9n+0){
    .nameInitialBadge{
        background-color: #EF705F;
    }
  }
  &:nth-child(10n+0){
    .nameInitialBadge{
        background-color: #AAAFB8;
    }
  }
}

/*-- Custom alert css --*/


.hide-show-tbl-row thead tr th:first-child, .hide-show-tbl-row tfoot tr td:first-child{
  min-width: 35px!important;
  width: 35px!important;
}
.hide-show-tbl-row .has-link {
  padding: 0px!important;
}

  
  .flex-block,
  .form-inner-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex !important;
    width: 100%;
    @media (max-width: 1024px) {
      -webkit-flex-wrap: wrap;
      -ms-flex-flow: row wrap;
      flex-wrap: wrap;
    }
  }
  .bold-text {
    font-weight: bold !important;
  }
  .row {
    max-width: none;
  }
  
  .transaction-hide-checkbox .MuiListItemIcon-root,
  .hide-label label {
    display: none !important;
  }
  /*----*/
  /*Custom-Margins*/
  .no-border,
  .MuiDrawer-paper {
    border-width: 0px !important;
  }
  .mar-15 {
    margin: 15px 3px 3px 3px;
  }
  .mlr-10 {
    margin: 0 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .no-margin,
  .cndt-row .with-select,
  .form-wrapper .mui-custom-form .set-no-margin .MuiInput-root,
  .form-wrapper.sub-radio-group .sub-radio p.checkbox-error,
  .form-wrapper .mui-custom-form.no-margin,
  .no-margin-label label {
    margin: 0px !important;
  }
  .no-margin-x {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .no-margin-y,
  .custom-form-feild .MuiInput-multiline textarea {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .space {
    width: 4px;
    height: auto;
    display: inline-block;
  }
  /*Custom-paddings*/
  .no-padding,
  .custom-form-feild .MuiInput-multiline,
  .MuiList-padding,
  .MuiTypography-body1 .MuiBox-root,
  .set-no-pading .MuiButtonBase-root {
    padding: 0px !important;
  }
  .py-2 {
    padding-top: 5px;
    padding-bottom: 10px;
  }
  /*Custom-flex*/
  .justify-end {
    justify-content: flex-end !important;
  }
  .justify-space-bw {
    justify-content: space-between !important;
  }
  .align-end {
    align-items: flex-end;
  }
  /*Custom-styles*/
  .white-bg,
  .cndt-textArea {
    background-color: #ffffff !important;
  }
  .pos-relative {
    position: relative;
  }
  
  .editable-table-container {
    margin-top: 10px;
  }
  
  .mui-custom-form-table {
    .MuiInput-root {
      margin-top: unset;
      border: 1px solid #707070;
      border-radius: 5px;
      padding-left: 5px;
    }
    .MuiSelect-select {
      width: 140px;
    }
  }
  .w-30 {
    width: 30%;
  }
  .font-12,
  .txt-area {
    font-size: 12px;
  }
  /*--Mixins Starts Here--*/
  @mixin border-radius($val) {
    border-radius: $val;
    -webkit-border-radius: $val;
    -ms-border-radius: $val;
  }
  @mixin boxShadow($val) {
    box-shadow: $val;
    -webkit-box-shadow: $val;
    -ms-box-shadow: $val;
  }
  @mixin animate-css($props) {
    transition: $props !important;
    -webkit-transition: $props !important;
    -moz-transition: $props !important;
  }
  /*--|--*/
  /*--Block level main parents--*/
  .main-body {
    position: relative;
    min-height: 100vh;
    background-color: #f4f4f4 !important;
    padding: 15px 0 0;
  }
  #root {
    height: 100% !important;
  }
  .makeStyles-root-1,
  .wid-100,
  .fm-inline-btn {
    width: 100% !important;
  }
  .form-wrapper .input-md.wid-100 {
    &,
    .clm-inquiry-tabData & {
      @media (max-width: 1024px) {
        width: 100% !important;
      }
    }
  }
  .main-content {
    width: 100%;
    min-height: calc(100vh - 82px);
    position: relative;
    padding: 0px 10px 0px 20px;
    margin: 0;
    z-index: 12;
  }
  .main-content::-webkit-scrollbar {
    width: 10px;
  }
  .main-content::-webkit-scrollbar-track {
    background: #eeeeee;
  }
  .main-content::-webkit-scrollbar-thumb {
    background: #aaafb9;
    @include boxShadow(inset 0 0 6px #999999);
    outline: 2px solid #999999;
    z-index: 99;
  }
  .content-wrapper {
    @include boxShadow(0 10px 40px rgba(24, 39, 56, 0.11));
    padding: 80px 15px 15px !important;
    min-height: calc(100vh - 130px);
  }
  .main-footer,
  .expand-more {
    width: 100%;
    padding-top: 15px;
  }
  .main-footer-100 {
    width: 100% !important;
    left: 0 !important;
  }
  
  .required-label {
    margin-right: 5px;
  }
  .full-menu {
    .main-content {
      width: 100% !important;
      height: 100%;
    }
    .main-footer {
      width: 100%;
      left: 0px;
    }
  }
  .top-brand-border {
    left: 55px;
    top: 0;
    width: 206px;
    height: 6px;
    position: fixed;
    transform: skew(45deg);
    -webkit-transform: skew(45deg);
    background: #ff8700;
    z-index: 1;
  }
  /*----*/
  
  /*--Page Header starts here--*/
  .MuiAppBar-colorPrimary {
    background-color: #fff !important;
    @include boxShadow(1px 0px 4px rgba(0, 0, 0, 0.1) !important);
    border: 1px solid rgba(25, 44, 64, 0.1);
  }
  .top-navbar {
    display: block;
    padding: 0 15px;
    margin: 0;
    position: absolute !important;
    top: 0px !important;
    right: auto !important;
    background: #ffffff !important;
    z-index: 11 !important;
    @include boxShadow(1px 0px 8px rgba(0, 0, 0, 0.1) !important);
    height: 65px;
    width: calc(100% - 30px) !important;
    @include border-radius(5px !important);
    .MuiToolbar-regular {
      padding-right: 0px;
    }
    .top-logo-bar {
      height: 65px;
      width: 100%;
      max-width: calc(100% - 200px);
      position: absolute;
      top: 0px;
      z-index: 1;
      overflow: hidden;
      display: flex;
      align-items: center;
      a {
        display: inline-block;
        height: 60px;
        padding-right: 12px;
        img {
          height: 55px;
          width: auto;
        }
      }
      .logo-text {
        font-weight: bold;
        color: #000;
        line-height: 1;
        font-size: 16px;
      }
    }
  }
  .nav-user-dropdown {
    button.dropdown-toggle {
      border: solid 1px rgba(0, 0, 0, 0) !important;
      &:focus {
        border: solid 1px transparent !important;
      }
    }
    button.dropdown-toggle::after{
      display: none;
    }
    button.btn-secondary:hover{
      background-color: white !important;
    }
    button.btn-secondary:focus{
      background-color: white !important;
    }
    .dropdown-menu.show {
      min-Width: 320px !important;
      right: 0px !important;
      margin: 15px !important;
      left: auto !important;
      transform: translate3d(0px, 45px, 0px) !important;
      -webkit-transform: translate3d(0px, 45px, 0px) !important;
      @include border-radius(4px);
    }
  }
  /*----*/
  
  /*--Side Navigation CSS--*/
  #sideNav::-webkit-scrollbar,
  .leftNavOff .MuiDrawer-paper::-webkit-scrollbar,
  .rec-scroll::-webkit-scrollbar,
  .slim-scroll::-webkit-scrollbar,
  .MuiInput-formControl.MuiInput-multiline
    .MuiInput-inputMultiline::-webkit-scrollbar {
    width: 6px;
  }
  #sideNav::-webkit-scrollbar-track,
  .leftNavOff .MuiDrawer-paper::-webkit-scrollbar-track,
  .rec-scroll::-webkit-scrollbar-track,
  .slim-scroll::-webkit-scrollbar-track,
  .MuiInput-formControl.MuiInput-multiline
    .MuiInput-inputMultiline::-webkit-scrollbar-track {
    background: #bbbbbb;
    @include border-radius(5px);
  }
  /* Handle */
  #sideNav::-webkit-scrollbar-thumb,
  .leftNavOff .MuiDrawer-paper::-webkit-scrollbar-thumb,
  .rec-scroll::-webkit-scrollbar-thumb,
  .slim-scroll::-webkit-scrollbar-thumb,
  .MuiInput-formControl.MuiInput-multiline
    .MuiInput-inputMultiline::-webkit-scrollbar-thumb {
    background: #999999;
    @include boxShadow(inset 0 0 6px #666666);
    @include border-radius(5px);
    z-index: 99;
  }
  /* Handle on hover */
  #sideNav::-webkit-scrollbar-thumb:hover,
  .leftNavOff .MuiDrawer-paper::-webkit-scrollbar-thumb:hover,
  .rec-scroll::-webkit-scrollbar-thumb:hover,
  .slim-scroll::-webkit-scrollbar-thumb:hover,
  .MuiInput-formControl.MuiInput-multiline
    .MuiInput-inputMultiline::-webkit-scrollbar-thumb:hover {
    background: #666666;
    @include border-radius(5px);
  }
  #sideNav {
    scrollbar-color: #666666 #999999;
    scrollbar-width: thin;
    @include border-radius(5px);
    padding-left: 5px;
  }
  .App-logo {
    height: 40vmin;
  }
  .full-menu .side-drawer {
    width: 0px !important;
  }
  .side-drawer {
    .MuiPaper-root {
      padding: 0 0 10px;
      background: none !important;
      z-index: 9 !important;
      top: 22px;
      height: calc(100% - 20px);
      overflow-y: hidden;
    }
  }
  .scollnavBar {
    height: calc(100% - 25px);
  }
  .MuiList-padding,
  .db-space {
    padding: 0 5px 5px !important;
  }
  .poweredBy {
    font-size: 12px;
    font-weight: 600;
    padding-top: 4px;
    padding-left: 10px;
  }
  .makeStyles-drawerPaper-7,
  .side-drawer,
  .jss7 {
    width: 245px !important;
  }
  /*-----*/
  .custom-dialog {
    .MuiDialogTitle-root {
      background-color: $PrimaryColor;
      padding: 12px;
      .MuiTypography-root {
        color: #fff !important;
        font-size: 16px;
        font-weight: 500;
      }
      .MuiIconButton-root {
        color: #fff;
      }
      .MuiButtonBase-root {
        position: absolute;
        top: 0px;
        right: 8px;
      }
    }
    .MuiDialogContent-root {
      padding: 0px;
    }
  }
  .custom-dialog.dialog-450 {
    .MuiDialog-paperWidthSm {
      max-width: 450px;
    }
  }
  .custom-dialog.dialog-500 {
    .MuiDialog-paperWidthSm {
      max-width: 500px;
    }
  }
  .custom-dialog.dialog-614 {
    .MuiDialog-paperWidthSm {
      max-width: 614px;
    }
  }
  .custom-dialog.dialog-625 {
    .MuiDialog-paperWidthSm {
      max-width: 625px;
    }
  }
  .custom-dialog.dialog-700 {
    .MuiDialog-paperWidthSm {
      max-width: 700px;
    }
  }
  .custom-dialog.dialog-800 {
    .MuiDialog-paperWidthSm {
      max-width: 800px;
    }
    .MuiDialogContent-root::-webkit-scrollbar {
      display: none !important;
    }
  }
  .App-logo {
    height: 40vmin;
  }
  .original-tabred {
    background-color: $ErrorColor !important;
  }
  .welcometxt {
    color: black;
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    width: 100%;
  }
  .navbar-blue-bg {
    background-color: $PrimaryColor !important;
    border-bottom: 1px solid #fff !important;
  }
  .navbar-blue-bg .MuiSvgIcon-root,
  .MuiTableRow-head .MuiTableSortLabel-root:hover,
  .MuiTableRow-head .MuiTableSortLabel-root:focus,
  .MuiTableRow-head .MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #fff !important;
  }
  .MuiTableRow-head .MuiCheckbox-root {
    padding: 0 5px !important;
  }
  .MuiInputLabel-root,
  .mui-custom-form .MuiChip-label,
  .mui-custom-form .MuiChip-avatar {
    color: #000000 !important;
  }
  .err-color {
    color: $ErrorColor;
  }
  .dib,
  .block-elm,
  .fm-inline-btn {
    display: inline-block !important;
  }
  .dif,
  .dis-flex,
  .cndt-textArea {
    display: flex !important;
  }
  /*** Styling Form Elements start ***/
  .custom-panel {
    .panel-header {
      padding: 8px 15px !important;
      background-color: $PrimaryColor;
      color: #ffffff;
      font-size: 15px;
      font-weight: 600;
    }
    .panel-header-sm {
      padding: 6px 15px !important;
      font-size: 0.89em;
    }
  }
  .custom-panel {
    .panel-header-white {
      background-color: #fff !important;
      color: $PrimaryColor !important;
      font-size: 1.05rem;
      border-bottom: 1px solid #ececec;
    }
  }
  .custom-panel-1 {
    border: 1px solid #e0e0e0;
    @include border-radius(5px);
    .tab-header {
      border-bottom: 1px solid #e0e0e0;
      padding: 0 5px !important;
    }
  }
  .custom-hr,
  .tab-footer,
  .tab-holder {
    width: 100%;
    display: block;
    clear: both;
  }
  .custom-hr {
    height: 1px;
    border-bottom: 1px solid #e0e0e0;
  }
  .set-top-m2,
  .sub-radio.set-top-m2 .MuiCheckbox-root,
  .sub-radio .MuiCheckbox-root.jss405,
  .MuiTextField-root + .sub-radio > .MuiCheckbox-root,
  .MuiFormLabel-root + .sub-radio > .MuiCheckbox-root {
    position: relative;
    top: -2px;
  }
  .disable-theme {
    background: #f4f4f4 !important;
    border-color: #eee !important;
  }
  /*** ------ ***/
  
  /*** Styling Form Elements start ***/
  span.err-red,
  .alert-danger {
    color: $ErrorColor !important;
  }
  .mui-custom-form {
    margin: 12px 16px;
    .Mui-error,
    .Mui-required,
    .MuiFormLabel-asterisk {
      color: $ErrorColor !important;
    }
    .Mui-disabled {
      &.MuiInputBase-root {
        @extend .disable-theme;
        pointer-events: none;
      }
    }
    .MuiFormControl-root {
      width: 100% !important;
      max-width: calc(100% - 2px) !important;
    }
    .MuiFormLabel-root,
    .MuiCardHeader-root .MuiTypography-h5 {
      font-size: 1.22em !important;
      white-space: nowrap;
      color: $PrimaryColor !important;
      font-weight: 700;
      letter-spacing: 0.045em;
      -webkit-font-smoothing: antialiased;
      text-transform: capitalize;
    }
    .MuiInput-root {
      margin-top: 23px;
      border: 1px solid #707070;
      @include border-radius(5px);
      padding: 2px 10px;
      height: 38px;
      @include animate-css(all 0.2s linear);
      &:after,
      &:before {
        border: none !important;
      }
      .MuiIconButton-root {
        padding: 10px 5px;
        &:focus {
          outline: none;
        }
      }
    }
    .MuiInput-root:hover,
    .MuiInput-root:focus {
      border-color: $PrimaryColor;
    }
    .MuiInput-input {
      color: #000;
      font-size: 12px;
      height: 1.1875em;
      box-sizing: content-box;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    .MuiInput-inputMultiline {
      min-height: 16px !important;
      max-height: 65px !important;
      padding: 6px 0 7px;
      overflow: auto !important;
      resize: vertical !important;
    }
    .Mui-disabled {
      .MuiInput-input {
        color: #545955;
      }
      &::before {
        border-bottom-width: 0px;
      }
    }
    .MuiFormLabel-root.small-label {
      font-size: 14px !important;
      margin-bottom: 12px;
      span {
        color: $ErrorColor;
      }
    }
    legend {
      width: 60px;
    }
    .input-text {
      color: #000;
      font-size: 16px;
    }
    .sub-radio {
      margin-top: 10px;
      font-size: 13px;
      overflow: hidden;
      label {
        margin: 0px;
      }
      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }
      .MuiCheckbox-root {
        padding: 0px 4px 0px 0px;
        position: relative;
      }
      .Mui-checked {
        .MuiSvgIcon-root {
          color: $PrimaryColor;
        }
        &:hover {
          background-color: rgba(39, 68, 99, 0.08);
        }
      }
      .MuiFormControlLabel-label {
        font-size: 13px;
        font-weight: 400;
        padding-top: 2px;
        color: #545955;
        position: relative;
        left: -3px;
      }
    }
    .set-sub-radio {
      position: relative;
      left: -10px;
    }
    .MuiSelect-select {
      margin-top: 3px;
    }
  
    .Table-select {
      width: 140px !important;
    }
  
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    input[type='number'] {
      -moz-appearance: textfield; /* Firefox */
    }
    @media (max-width: 591px) {
      width: 100%;
      .MuiTextField-root,
      .MuiInput-input {
        width: 100% !important;
      }
    }
    .MuiInputAdornment-positionStart {
      margin-right: 6px;
    }
  }
  .cndt-input {
    min-height: 38px;
    max-height: 38px;
  }
  .set-details-radio {
    margin-top: -5px !important;
    .MuiFormGroup-row {
      margin-left: 0 !important;
    }
    .MuiFormControlLabel-root {
      margin-left: 0 !important;
      margin-bottom: 0 !important;
      .MuiButtonBase-root {
        padding: 4px !important;
      }
      .MuiTypography-root {
        padding-top: 3px;
        font-size: 1em;
      }
    }
  }
  .disabled-form textarea,
  .disabled-form input,
  .disabled-view-data,
  #hcpcs-text-description:disabled {
    @extend .disable-theme;
    @include border-radius(5px);
    padding: 6px;
    overflow: hidden;
  }
  .sub-radio-group {
    .sub-radio {
      margin-right: 15px;
      width: calc(33% - 20px);
      display: flex;
      align-items: flex-start;
      @media (max-width: 1024px) {
        width: calc(50% - 30px);
      }
      .leftNavOff & {
        width: calc(25% - 30px);
        @media (max-width: 1024px) {
          width: calc(33% - 30px);
        }
      }
      label {
        padding-top: 9px;
        display: inline-flex;
        white-space: normal;
      }
      p {
        padding-left: 10px;
        margin-bottom: 5px;
        width: 100%;
      }
    }
  }
  .sub-radio,
  .radio-group,
  .sm-inline-radio {
    .Mui-checked .MuiSvgIcon-root {
      color: $PrimaryColor;
    }
  }
  .inline-radio-label {
    display: inline-flex;
    align-items: center;
  }
  .checkbox-error {
    color: $ErrorColor !important;
    margin: 12px;
    margin-left: 12px;
    flex: 1 100%;
    font-size: 0.75rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1em;
    letter-spacing: 0.03333em;
  }
  .cndt-label {
    color: $PrimaryColor !important;
    font-weight: 700;
    font-size: 0.9em !important;
    @extend .set-top-m2;
  }
  .cndt-label.lb-1 {
    top: 3px !important;
  }
  .form-wrapper {
    padding: 8px 3px;
    .mui-custom-form,
    .custom-form-wrapp-inner {
      margin: 8px 10px !important;
      width: calc(25% - 20px);
      &.field-md {
        width: calc(50% - 20px) !important;
        max-width: calc(50% - 20px) !important;
        min-width: calc(50% - 20px) !important;
      }
      &.field-lg {
        width: calc(75% - 20px) !important;
        max-width: calc(75% - 20px) !important;
      }
      &.field-xl {
        width: calc(100% - 20px) !important;
        max-width: calc(100% - 20px) !important;
      }
      @media (max-width: 1200px) {
        .lg-float-right {
          float: right !important;
        }
        &.lg-field-xl {
          width: calc(100% - 20px) !important;
          max-width: calc(100% - 20px) !important;
          -ms-flex: 0 0 calc(100% - 20px) !important;
          flex: 0 0 calc(100% - 20px) !important;
        }
      }
      @media (max-width: 1024px) {
        &.w-100 {
          width: 100% !important;
        }
        &.md-field-md,
        &.md-field-lg {
          width: calc(66.66% - 20px) !important;
          max-width: calc(66.66% - 20px) !important;
          min-width: calc(66.66% - 20px) !important;
          -ms-flex: 0 0 calc(66.66% - 20px) !important;
          flex: 0 0 calc(66.66% - 20px) !important;
        }
        &.md-field-xl,
        &.field-xl {
          width: calc(100% - 20px) !important;
          max-width: calc(100% - 20px) !important;
          -ms-flex: 0 0 calc(100% - 20px) !important;
          flex: 0 0 calc(100% - 20px) !important;
        }
      }
      .clm-inquiry-tabData &:not(.field-xl) {
        @media (max-width: 1200px) {
          width: calc(33.33% - 20px) !important;
          max-width: calc(33.33% - 20px) !important;
          &.lg-field-xl {
            width: calc(100% - 20px) !important;
            max-width: calc(100% - 20px) !important;
            -ms-flex: 0 0 calc(100% - 20px) !important;
            flex: 0 0 calc(100% - 20px) !important;
          }
        }
        @media (max-width: 992px) {
          width: calc(50% - 20px) !important;
          max-width: calc(50% - 20px) !important;
        }
      }
    }
    .mui-custom-form.inner-form-fields,
    .sr-criteria-w1 {
      margin: 0px !important;
    }
    .custom-form-wrapp-inner {
      margin-top: 0 !important;
      .mui-custom-form {
        margin-left: 0 !important;
        width: calc(49.5% - 10px) !important;
      }
      &.flex-inner {
        display: flex;
        -webkit-display: flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        .mui-custom-form {
          flex: 0 0 47%;
          -webkit-flex: 0 0 47%;
          width: 47% !important;
          margin-right: 0 !important;
        }
      }
    }
    .mui-custom-form {
      .MuiInputBase-adornedEnd {
        .MuiInputBase-input {
          height: 1.38em;
        }
      }
      .mui-custom-check {
        margin: 2em 0 0;
        .PrivateSwitchBase-root-264,
        .PrivateSwitchBase-root-349,
        .PrivateSwitchBase-root-301,
        .PrivateSwitchBase-root-343 {
          padding: 0 9px;
        }
      }
      label.mui-custom-check {
        .PrivateSwitchBase-root-264 {
          margin-left: -9px;
        }
      }
      .MuiFormGroup-row {
        .MuiFormControlLabel-root {
          margin-bottom: 0;
          .PrivateSwitchBase-root-349,
          .PrivateSwitchBase-root-301 {
            padding: 0px 5px;
          }
        }
      }
    }
    &.form-3-column {
      .mui-custom-form,
      .custom-form-wrapp-inner {
        width: calc(33.333% - 20px) !important;
        max-width: calc(33.33% - 20px) !important;
      }
      .custom-form-wrapp-inner {
        .mui-custom-form {
          width: calc(49.5% - 20px) !important;
          max-width: calc(49.5% - 20px) !important;
          @media (max-width: 768px) {
            width: calc(50% - 10px) !important;
            max-width: calc(50% - 10px) !important;
          }
        }
      }
    }
    .set-form-wrapper & {
      margin: 0 -10px;
      max-width: none;
    }
    .flex-block {
      -webkit-flex-wrap: wrap;
      -ms-flex-flow: row wrap;
      flex-wrap: wrap;
    }
  }
  .radio-space-sm {
    .MuiFormGroup-row {
      .MuiFormControlLabel-root {
        margin-bottom: 0;
        align-items: flex-start;
        .MuiButtonBase-root {
          padding: 0px 5px !important;
        }
      }
    }
  }
  .field-sm,
  .field-sm-radio .sub-radio {
    width: calc(25% - 20px) !important;
    max-width: calc(25% - 20px) !important;
    min-width: calc(25% - 20px) !important;
  }
  .form-2-column {
    .mui-custom-form,
    .custom-form-wrapp-inner {
      width: calc(50% - 20px) !important;
    }
  }
  .form-2-column-custom {
    .mui-custom-form-custom,
    .custom-form-wrapp-inner {
      width: calc(67% - 20px) !important;
    }
    .mui-custom-form-custom-sec,
    .custom-form-wrapp-inner {
      width: calc(33% - 20px) !important;
      @media (max-width: 768px) {
        width: calc(33% - 20px) !important;
      }
      @media (max-width: 600px) {
        width: calc(100% - 20px) !important;
      }
    }
  }
  .form-1-column {
    .mui-custom-form,
    .custom-form-wrapp-inner {
      width: calc(100% - 20px) !important;
    }
  }
  .inner-form-fields [class^='col-'] {
    margin-bottom: 10px;
  }
  /* Direct custom input width */
  .wid-auto {
    width: auto !important;
  }
  .form-wrapper {
    .input-md-1-col {
      width: calc(100% - 20px) !important;
    }
    /*we can use in 4column(regular) layout*/
    .input-md-2-col {
      width: calc(50% - 20px) !important;
    }
    /*we can use in 3-column layout*/
    &.form-3-column {
      .input-md-1-col,
      .w-100,
      .wid-100 {
        width: calc(100% - 20px) !important;
      }
      .input-md-2A-col {
        width: calc(66.666% - 20px) !important;
      }
    }
    /*we can use in 4column(regular) layout*/
    .input-md-2B-col {
      width: calc(75% - 20px) !important;
    }
    /*we can use for last, 1st name */
    .input-md-60-p {
      width: calc(60% - 20px) !important;
    }
    .input-md-40-p {
      width: calc(40% - 20px) !important;
    }
    .input-md-37-p {
      width: calc(37.5% - 20px) !important;
      @media (max-width: 1024px) {
        width: calc(66.66% - 20px) !important;
      }
    }
    .input-md-2B-col,
    .input-md-2-col,
    .input-md-1-col,
    .input-md-60-p,
    .input-md-40-p,
    .input-md-37-p,
    &.form-3-column .input-md-2A-col {
      @media (max-width: 768px) {
        width: calc(50% - 20px) !important;
      }
    }
    .input-md-2B-col,
    .input-md-2-col,
    .input-md-1-col,
    .input-md-60-p,
    .input-md-40-p,
    .input-md-37-p,
    &.form-3-column .input-md-2A-col {
      @media (max-width: 600px) {
        width: calc(100% - 20px) !important;
      }
    }
  }
  .wrap-header-1 {
    max-width: 325px;
    @extend .dib;
  }
  .wid-50p {
    width: 50%;
  }
  /* ------------------------- */
  label.Mui-required {
    padding-left: 10px;
    .MuiFormLabel-asterisk {
      position: absolute;
      left: -5px;
    }
  }
  .Mui-required-lbl {
    font-weight: 700;
    position: relative;
    left: -2px;
    top: 1px;
  }
  .field-label-txt {
    padding-bottom: 0 !important;
    .mui-custom-form {
      margin: 8px 6px 0px !important;
    }
  }
  .form-wrap-inner,
  .tbody-space,
  .container-space {
    padding: 0 14px 1rem !important;
  }
  .accord-space {
    padding: 5px 12px 15px !important;
  }
  .custom-form-feild {
    .MuiInput-underline,
    .MuiInput-underline:before,
    .MuiInput-underline:hover:before,
    .MuiInput-underline:after {
      border: none !important;
    }
    textarea,
    input,
    select {
      padding: 12px 10px;
      border: solid 1px #707070;
      min-width: 65%;
      margin-bottom: 12px;
      @include border-radius(5px);
      &:disabled {
        border-color: #eee;
      }
    }
  }
  .row-feilds {
    [class^='cndt-col-'] {
      padding-bottom: 0.6rem;
    }
  }
  .clm-inquiry {
    .mui-custom-form {
      &.input-md .MuiFormLabel-root {
        height: 35px;
        max-width: 100%;
        display: flex;
        font-size: 14px !important;
        white-space: normal;
        align-items: center;
        line-height: 1.2;
        transform: none !important;
      }
      .MuiInput-root {
        margin-top: 40px;
      }
    }
    .sub-radio {
      margin-top: 10px !important;
    }
  }
  .input-style-text {
    display: inline-block !important;
    .MuiInput-root {
      background: #f4f4f4 !important;
      border: solid 1px #eee !important;
      padding: 2px 8px !important;
      color: #868f92 !important;
      width: auto !important;
      display: inline-flex !important;
      align-items: center;
      max-width: 450px;
      min-width: 190px;
      label {
        margin: 0;
      }
    }
  }
  .list-columns-3 {
    display: grid;
    -webkit-display: grid;
    grid-template-rows: repeat(6, auto);
    -webkit-grid-template-rows: repeat(6, auto);
    grid-auto-flow: column;
    -webkit-grid-auto-flow: column;
    @media (max-width: 1024px) {
      grid-template-rows: repeat(8, auto);
      -webkit-grid-template-rows: repeat(8, auto);
      &.sub-radio-group .sub-radio {
        width: calc(100% - 30px) !important;
      }
    }
  }
  .wrap-rd,
  .list-columns-3.sub-radio-group {
    .sub-radio {
      width: calc(100% - 30px) !important;
    }
    .leftNavOff & {
      .sub-radio {
        width: calc(100% - 30px) !important;
      }
    }
  }
  .radio-group .MuiFormControlLabel-root {
    width: 100%;
  }
  /*** Styling custom tables starts here ***/
  /* .clean-table {
  .table-wrapper {
    border: solid 1px #dddddd;
     @include border-radius(5px);
  }
  .customDataTable .MuiTableHead-root {
    background-color: #fff !important;
  }
  .MuiTableSortLabel-root {
    color: $PrimaryColor !important;
  }
  &.tbl-no-border {
    tbody, tfoot {
    .MuiTableCell-root {
      border: none;
    }
  }
    .MuiTableFooter-root {
        border-top: 1px solid #e0e0e0;
    }
    
  }
  }
  */
  .hide-rpp,
  .cndt-col-6 {
    .MuiTablePagination-caption {
      //display: none;
      text-decoration: none;
    }
    .MuiTablePagination-select {
      padding-left: 5px;
    }
    .MuiTablePagination-toolbar {
      padding-left: 0 !important;
    }
    .MuiTablePagination-selectRoot {
      margin: 0 5px;
    }
  }
  /*** Styling table Elements end ***/
  
  .original-tabred {
    background-color: $ErrorColor !important;
  }
  .original-tabgreen {
    background-color: $SuccessColor !important;
  }
  .MuiFormHelperText-root.Mui-error.Mui-required,
  .Mui-red,
  .Mui-red *,
  .danger-text .MuiFormLabel-asterisk.MuiInputLabel-asterisk.Mui-error,
  .Mui-required-lbl,
  .MuiFormLabel-asterisk.MuiInputLabel-asterisk.Mui-error,
  .required-label {
    color: $ErrorColor !important;
  }
  .Mui-red {
    font-weight: bold;
  }
  .mandatory-txt {
    color: #222222;
    font-size: 0.9rem;
  }
  .MuiInput-underline.Mui-error:after {
    border-bottom-color: $ErrorColor !important;
  }
  /*--collapsable-panel starts--*/

  .MuiTabs-indicator {
    background-color: $PrimaryColor !important;
  }
  .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 40px !important;
    @include border-radius(4px 4px 0px 0px);
  }
  .MuiExpansionPanelSummary-content {
    &,
    .Mui-expanded {
      margin: 8px 0 !important;
    }
  }
  .CustomExpansion-panel {
    .MuiPaper-elevation1 {
      border: none;
      box-shadow: none;
    }
    .MuiButtonBase-root + .MuiCollapse-container,
    .MuiButtonBase-root + .MuiCollapse-root {
      max-height: none;
      //overflow: unset;
      border: solid 1px #d6d6d6;
      @include border-radius(0px 0px 4px 4px);
    }
    .MuiExpansionPanelSummary-root {
      .MuiTypography-root {
        padding: 0 !important;
        font-size: 1.08rem;
      }
      .MuiIconButton-root {
        padding: 0.8rem 0.95rem;
      }
    }
  }
  .inner-collapse-container {
    margin: 0 0.65rem 0.8rem;
  }
  /*-- CustomExpansion-panel ends here--*/
  .mui-custom-form .MuiGrid-item {
    padding-left: 0 !important;
    padding-right: 10px;
    .MuiList-root {
     // min-width: 240px;
      @media (min-width: 992px) {
       min-width: 250px;
      }
      padding-left: 0 !important;
      .MuiListItem-gutters {
        padding: 4px;
      }
      .MuiListItemIcon-root {
        min-width: 40px;
      }
    }
  }
  .form-wrapper {
    .form-row-1 {
      position: relative;
      margin-left: 0 !important;
      .MuiFormControl-root {
        flex-direction: row;
        align-items: center;
      }
      .MuiInput-root {
        margin-top: 0;
      }
      .MuiInputLabel-formControl {
        position: relative !important;
        transform: translate(0, 1.5px) scale(0.82);
      }
    }
  }
  /*----*/
  
  .App-header {
    background-color: #000000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .label-gender {
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    margin-right: 5px;
  }
  
  /*--Buttons CSS--*/
  .btn {
    color: white !important;
    font-weight: 600 !important;
    font-size: 0.85rem !important;
    padding: 8px 15px !important;
    border-width: 1px !important;
    @include border-radius(5px !important);
    @include boxShadow(6px 3px 12px rgba(39, 68, 99, 0.3) !important);
    text-transform: uppercase;
    position: relative;
    text-decoration: none !important;
    .fa {
      font-size: 12px;
      margin-right: 5px;
    }
  }
  .btn-primary,
  .btn-search,
  .btn-print,
  .btn-cancel,
  .btn-validate,
  .btn-copy,
  .btn-refresh-upd,
  .btn-filter,
  .btn-maintenance,
  .btn-view-find,
  .btn-unlock,
  .btn-activate,
  .btn-view-primary,
  .btn-deactivate,
  .btn-reset-active {
    background-color: $PrimaryColor !important;
    border-color: $PrimaryColor !important;
    &:hover,
    &:focus {
      background-color: #2f5b8a !important;
    }
  }
  .btn-success,
  .btn-save,
  .btn-add,
  .btn-update,
  .btn-replace,
  .btn-transfer {
    background-color: #006e62 !important;
    border-color: #005a64 !important;
    &:hover,
    &:focus {
      background-color: #005a64 !important;
    }
  }
  .btn-save.disabled,
  .btn-save:disabled,
  .btn-reset.disabled,
  .btn-reset:disabled,
  .btn-delete:disabled,
  .btn-unlock:disabled,
  .btn-activate:disabled,
  .btn-deactivate:disabled .btn-transfer.disabled,
  .btn-transfer:disabled,
  .btn-reset-active.disabled {
    color: #545955 !important;
    background-color: #dadddc !important;
    border-color: #aaafb9 !important;
    &:hover,
    &:focus {
      background-color: #dadddc !important;
    }
  }
  
  .btn-secondary,
  .btn-help {
    background-color: $SecondaryColor !important;
    border-color: $SecondaryColor !important;
    &:hover,
    &:focus {
      background-color: #0a50c1 !important;
    }
  }
  
  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: #fff !important;
    background-color: #cacdcb !important;
    border-color: #cacdcb !important;
  }
  .btn-transparent,
  .btn-delete,
  .btn-view,
  .btn-notes,
  .btn-reset,
  .btn-audit,
  .btn-filter-transparent {
    background-color: #fff !important;
    border: solid 1px $PrimaryColor !important;
    color: $PrimaryColor !important;
    &:hover,
    &:focus {
      background-color: #f8f8f8 !important;
    }
  }
  .btn-transparent.disabled,
  .btn-transparent:disabled,
  .btn-transparent-noborder.disabled,
  .btn-transparent-noborder:disabled {
    color: #6c757d !important;
    background-color: #fff !important;
    border-color: #cacdcb !important;
    .fa {
      color: #cacdcb !important;
    }
  }
  .btn-transparent-noborder {
    background-color: #fff !important;
    color: $PrimaryColor !important;
    &:hover,
    &:focus {
      background-color: #f8f8f8 !important;
    }
  }
  
  .btn-audit,
  .btn-notes,
  .btn-reset {
    &.selected {
      background: $PrimaryColor !important;
      color: white !important;
    }
  }
  .btn-reset {
    border-color: #ffffff !important;
    padding-left: 30px !important;
    &:hover,
    &:focus {
      background-color: #f2f2f2 !important;
    }
    i {
      display: none;
    }
  }
  
  .btn-icon-only-add,
  .btn-icon-only-save,
  .btn-icon-only-reset,
  .btn-icon-only-cancel,
  .btn-icon-only-delete {
    background-color: #fff !important;
    border: solid 0px $PrimaryColor !important;
    color: $PrimaryColor !important;
    box-shadow: none !important;
    &:hover,
    &:focus {
      background-color: #f8f8f8 !important;
    }
  }
  .btn-icon-only-shuffle,
  .btn-icon-only-info,
  .btn-icon-only-qst {
    background-color: #fff !important;
    border: solid 0px $PrimaryColor !important;
    color: #0047ba !important;
    box-shadow: none !important;
    &:hover,
    &:focus {
      background-color: #fff !important;
    }
  }
  .btn-icon-only-info,
  .btn-icon-only-qst {
    color: $PrimaryColor !important;
    line-height: 1 !important;
  }
  .btn-ic-2:before {
    font-size: 1.3rem !important;
  }
  .btn.btn-sm {
    padding: 3px 7px !important;
  }
  .th-btnGroup.mr-3 {
    margin-right: 16px !important;
  }
  .th-btnGroup .btn {
    margin-left: 5px;
  }
  .btn-ic {
    padding: 8px 15px 8px 30px !important;
  }
  .btn-ic-only {
    padding: 15px 20px 15px 23px !important;
  }
  .btn-ic-only-sm {
    padding: 7px 6px 7px 23px !important;
  }
  .btn-icon {
    padding: 8px 10px 8px 30px !important;
  }
  .btn-ic,
  .btn-icon {
    [class^='fa-'] {
      display: none;
    }
  }
  .btn:before,
  .btn-add-select:after {
    font-family: 'FontAwesomeIcon';
    font-size: 0.83rem;
    font-weight: 400;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
  }
  .btn-add-select:after {
    left: auto;
    right: 12px;
  }
  .btn-icon:before {
    font-size: 1.1rem !important;
  }
  .btn-save:before,
  .btn-update:before {
    content: '\f00c';
  }
  .btn-add:before {
    content: '\f067';
  }
  .btn-add-select {
    padding-right: 30px !important;
  }
  .btn-add-select:after {
    content: '\f107';
    font-size: 1.2rem;
  }
  .btn-menu-child {
    border-bottom: 1px solid #000000;
    padding: 0 5px 0 5px !important;
  }
  .btn-search:before {
    content: '\f002';
  }
  .btn-delete:before {
    content: '\f1f8';
    font-size: 0.9rem;
  }
  .btn-reset:before,
  .btn-reset-active:before {
    content: '\f0e2';
  }
  .btn-help:before {
    content: '\f059';
    font-size: 0.9rem;
  }
  .btn-print:before {
    content: '\f02f';
  }
  .btn-refresh-upd:before {
    content: '\f021';
  }
  .btn-notes:before {
    content: '\f15c';
  }
  .btn-view:before,
  .btn-view-find:before,
  .btn-view-primary:before {
    content: '\f06e';
  }
  .btn-validate:before {
    content: '\f00c';
  }
  .btn-filter:before {
    content: '\f0b0';
    font-size: 1rem !important;
  }
  .btn-copy:before {
    content: '\f24d';
  }
  .btn-audit:before {
    content: '\f274';
    font-size: 0.9rem;
    font-weight: 600;
  }
  .btn-replace:before {
    content: '\f0ec';
  }
  .btn-maintenance:before {
    content: '\f0ad';
  }
  .btn-icon-only-add:before {
    content: '\f067';
    font-size: 1.9rem;
    color: $SuccessColor !important;
  }
  .btn-icon-only-save:before {
    content: '\f00c';
    font-size: 1.9rem;
    color: $SuccessColor !important;
  }
  .btn-icon-only-reset:before {
    content: '\f0e2';
    font-size: 1.6rem;
  }
  .btn-icon-only-cancel:before {
    content: '\f00d';
    font-size: 1.9rem;
  }
  .btn-icon-only-delete:before {
    content: '\f1f8';
    font-size: 1.9rem;
  }
  .btn-icon-only-shuffle:before {
    content: '\f074';
    font-size: 0.9rem;
  }
  .btn-transfer:before {
    content: '\f0ec';
  }
  .pos-rel {
    position: relative;
    .btn-icon-only-info,
    .btn-icon-only-qst {
      position: absolute;
      right: 0;
      z-index: 1;
    }
  }
  .btn-icon-only-info:before {
    content: '\f05a';
    font-size: 0.9rem;
  }
  .btn-icon-only-qst:before {
    content: '\f059';
    font-size: 0.9rem;
  }
  .btn-unlock:before {
    content: '\f13e';
    font-size: 1.2rem;
    top: 55%;
  }
  .btn-activate:before {
    content: '\f00c';
  }
  .btn-deactivate:before {
    content: '\f05e';
    font-size: 1rem;
    color: #fff !important;
  }
  .cndt-icon {
    font-family: 'FontAwesomeIcon';
    display: inline-block;
    padding: 2px;
    font-size: 14px !important;
    &.ic-save:before,
    &.ic-check:before {
      content: '\f00c';
      color: $SuccessColor;
    }
    &.ic-close:before {
      content: '\f00d';
      color: $PrimaryColor;
    }
    &.ic-cross:before {
      content: '\f00d';
      color: $ErrorColor;
    }
    &.ic-reset:before {
      content: '\f0e2';
      color: $PrimaryColor;
    }
    &.ic-only {
      font-size: 1.2rem !important;
      line-height: 1;
      font-weight: normal;
    }
  }
  .fm-inline-btn {
    margin-top: 22px !important;
    .btn {
      min-height: 37px;
    }
  }
  .view-date-icon {
    position: relative;
    padding-right: 35px !important;
    &:after {
      content: '';
      width: 20px;
      height: 20px;
      background: url('../public/images/calendar-svg.svg') 0 0 / cover no-repeat;
      position: absolute;
      top: 8px;
      right: 15px;
      opacity: 0.4;
    }
  }
  @media only screen and (max-width: 1200px) and (min-width: 993px) {
    .wrap-form-label .mui-custom-form .fm-inline-btn.md-m-0,
    .mui-custom-form .fm-inline-btn.md-m-0 {
      margin-top: 0 !important;
    }
  }
  .custom-tabber .ic-group {
    position: absolute;
    top: 8px;
    right: 15px;
    z-index: 11;
    & + .MuiAppBar-root {
      padding-right: 120px;
      border-bottom: solid 1px #aaafb9;
      .MuiTabs-root {
        border: none;
      }
    }
  }
  /*-----*/
  /* tabber Block starts Here */
  .tabs-container {
    .tab-table-wrapper {
      padding: 15px 20px 0;
    }
  
    .tabs-container-inner {
      margin: 10px 0 4px;
      .tab-table-wrapper {
        padding: 15px 15px 15px 20px;
      }
      .tabs-container-inner {
        .tab-header {
          padding: 5px 16px;
        }
        .tab-body {
          border: solid 1px #f2f2f2;
          margin: 0 16px;
          @include boxShadow(0 2px 10px rgba(22, 38, 56, 0.06));
        }
      }
    }
  }
  
  /* tab Panel Block starts Here */
  .main-body main,
  .tabs-container .page-header {
    background: none;
  }
  .tab-body,
  .tab-body-bordered {
    color: #000;
    background-color: #fff;
    margin-top: 0.35em;
    @include border-radius(5px);
  }
  .tab-body-bordered {
    border: solid 1px #ececec;
    @include boxShadow(0 2px 10px rgba(22, 38, 56, 0.04));
  }
  .tab-inner-body,
  .panel-space {
    margin: 0 16px 5px;
  }
  .inner-bdr-wrap {
    padding: 1.15rem;
    margin-bottom: 10px;
  }
  .tab-body.tbl-wrap,
  .inr-tbl-wrap {
    padding: 10px 1.4rem;
  }
  .tab-header,
  .page-header {
    color: #ffffff !important;
    padding: 8px 0 5px;
    &.flex-theader {
      padding: 7px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .tab-heading,
    .page-heading {
      color: #000000;
      padding: 10px 0 5px;
      font-weight: 600;
      font-size: 1.135em;
      margin-bottom: 0px;
      text-transform: none;
      line-height: 1.4;
    }
    .txt-instruction {
      color: #555555;
      font-size: 0.95em;
    }
    .page-heading {
      font-size: 1.28rem;
    }
    .th-btnGroup {
      .btn {
        margin: 3px 0 0 10px;
      }
    }
    .float-right {
      .btn {
        margin-top: 3px;
        margin-bottom: 3px;
      }
    }
  }
  .page-header {
    padding: 8px 0 12px;
    @include boxShadow(none);
    // border-bottom: solid 1px #e0e0e0 !important;
    // margin-bottom: 1em !important;
  }
  .page-header.no-bdrs {
    padding: 0 0 12px;
    border-bottom: solid 0 #e0e0e0 !important;
    margin-bottom: 0 !important;
  }
  .tab-header.mini-tab-header {
    .tab-heading {
      padding: 7px 15px;
      font-size: 14px;
    }
    .btn-primary {
      padding: 3px 10px !important;
      font-size: 13px !important;
    }
  }
  .float-right {
    .btn + .btn {
      margin-right: 6px;
      margin-left: 0;
    }
    .btn:first-child + .btn {
      margin-left: 6px;
    }
    .btn + .btn:last-child,
    .btn:only-child {
      margin-right: 0;
    }
  }
  /* *--* */
  /* *- Login page css starts here -* */
  .login-container {
    height: 100%;
    padding: 20px 10px;
    background-color: #eeeeee;
    min-height: 100vh;
    .container-fluid {
      min-height: calc(100vh - 40px);
      div.white-bg {
        min-height: calc(100vh - 40px);
      }
    }
  }
  .log-bg {
    position: relative;
    background-color: $PrimaryColor;
    color: #fff;
  }
  .log-courosal {
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    overflow: hidden;
  }
  .log-bg,
  .log-courosal {
    @include border-radius(0px 12px 12px 0px);
  }
  .carousel.slide,
  .carousel-inner,
  .carousel-item {
    height: 100%;
  }
  .carousel-item img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  
  .exception-pod-btns {
    display: flex;
    margin-top: 2px;
  }
  .carousel-content {
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-flow: row nowrap;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    .carousel-text {
      color: #fff;
      padding: 0 30px;
      background: rgba(63, 89, 116, 0.01);
      text-align: left;
      @media (max-width: 1024px) {
        width: calc(100% - 105px);
        text-align: center;
        padding: 0 20px;
      }
      h4 {
        font-size: 18px;
        font-weight: 400;
      }
      h1 {
        font-size: 42px;
        font-weight: 300;
      }
    }
    .carousel-arr {
      color: #fff;
      height: 50px;
      width: 50px;
      @include border-radius(50%);
      text-align: center;
      cursor: pointer;
      line-height: 52px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      .fa {
        font-size: 24px;
      }
    }
    .carousel-count {
      padding-left: 45px;
      font-size: 10px;
      position: relative;
      display: inline-block;
      &::before {
        content: '';
        background-color: #ff8700;
        width: 40px;
        height: 2px;
        position: absolute;
        left: 0;
        top: 50%;
      }
    }
  }
  .log-action-center {
    height: calc(100% - 40px);
    .log-notifications {
      background: #2744637e 0% 0% no-repeat padding-box;
      @include border-radius(10px);
      padding: 20px 20px 15px 15px;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      margin-bottom: 12px;
      .notify-heading {
        color: #fff;
        padding-bottom: 5px;
        margin-left: 5px;
        font-size: 16px;
        font-weight: 600;
        border-bottom: solid 3px $SuccessColor;
        &.purple-brd {
          border-color: #b57fec;
        }
        &.green-brd {
          border-color: $SuccessColor;
        }
        .hd-toggle {
          float: right;
          padding-top: 5px;
          font-size: 10px;
          font-weight: 400;
          cursor: pointer;
        }
      }
      .notify-link {
        font-size: 13px;
        font-weight: 400;
        padding: 4px 5px;
        display: block;
        &:hover {
          background: #fff;
          color: black;
          @include border-radius(2px);
        }
      }
      label.updates {
        background-color: $ErrorColor;
        font-size: 8px;
        position: absolute;
        top: 8px;
        @include border-radius(10px);
        padding: 4px 7px;
      }
    }
  }
  .ligin-form {
    display: flex;
    align-items: center;
    vertical-align: middle;
    height: 100%;
  }
  .login-btn {
    border: solid 2px $PrimaryColor !important;
    &:hover {
      background-color: $PrimaryColor !important;
    }
  }
  .forgot-password {
    font-size: 13px;
    color: #0047ba !important;
    text-decoration: none !important;
  }
  /* *- ====== -* */
  /* *-Dashboard css starts here-* */
  .banner-section {
    max-height: 80px;
    position: relative;
    @include border-radius(5px);
    background: transparent linear-gradient(278deg, #327ea8 0%, #082d44 100%) 0%
      0% no-repeat padding-box;
    .image-responsive {
      @include border-radius(5px);
      min-height: 60px;
      img {
        max-height: 100%;
        max-width: 100%;
        @include border-radius(5px);
      }
    }
    .banner-text {
      position: absolute;
      color: #fff;
      top: 50%;
      left: 20px;
      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      .large-text {
        font-size: 24px;
      }
    }
  }
  .dashboard-heading {
    color: $PrimaryColor;
    padding: 12px 5px;
    font-weight: 600;
    font-size: 1.35em;
    letter-spacing: 0.1px;
    margin-bottom: 0px;
  }
  .label {
    @include border-radius(10px);
    padding: 2px 6px;
    &.label-danger {
      background-color: $ErrorColor;
    }
  }
  .alert-content {
    .alert-dangericon {
      color: #ffffff;
      font-size: 0.727em;
    }
    .alertview {
      color: #0047ba;
      text-align: right;
      float: right;
      padding: 13px;
      font-size: 0.727em;
    }
    .alert-contenttext {
      background-color: #fff8f0;
      color: #000000;
      @include border-radius(5px);
      padding: 5px;
      .alerts {
        float: left;
        font-size: 0.75em;
        text-align: left;
      }
      .alertsdate {
        float: left;
        font-size: 0.75em;
        color: #545955;
        text-align: left;
      }
    }
  }
  .upgrade-content {
    background-color: #0047ba !important;
    @include border-radius(5px);
    .upgrade-btn {
      padding: 11px 19px !important;
      font-size: 0.682em !important;
    }
  }
  .dashboard-hdg {
    color: #ffffff;
  }
  .db-r-data {
    .dashboard-heading {
      font-size: 1rem;
      padding: 5px 0 10px;
      border-bottom: solid 1px #e4e6e9;
      margin-bottom: 10px;
    }
    ul {
      list-style: inside;
      padding-left: 0px;
      color: rgb(87, 84, 84);
      font-size: 0.95rem;
      li {
        padding-bottom: 3px;
      }
    }
  }
  .dbImage1 {
    min-height: 160px;
    align-items: center;
    justify-content: center;
  }
  /*--Claim css Starts Here--*/
  .clm-inquiry-tabData {
    width: calc(100% - 195px);
    float: left;
  }
  .clm-inquiry-exceptionData {
    //position: -webkit-sticky; /* Safari */
    //position: sticky;
    //top: 0;
    width: 180px;
    float: left;
    margin-left: 15px;
    .panel-header {
      border-radius: 4px 4px 0px 0px;
      -webkit-border-radius: 4px 4px 0px 0px;
      -ms-border-radius: 4px 4px 0px 0px;
    }
  
    .data-panel {
      padding: 0 10px 5px;
    }
    .data-panel-title {
      border-bottom: 1px solid #e0e0e0;
    }
    a {
      color: $PrimaryColor !important;
    }
    .tcn-title {
      color: #555555;
      font-size: 0.88rem;
    }
    .tcn-no {
      color: #000000;
      font-size: 0.85rem;
    }
    .excp-title {
      color: #d80606;
      font-size: 0.9rem;
      font-weight: 600;
    }
    .cnv-title,
    .view-more {
      color: #205cbc !important;
      font-size: 0.9rem;
    }
    .table-wrapper {
      border: solid 1px #dddddd;
      @include border-radius(5px);
      font-size: 0.9rem;
      box-shadow: none !important;
      overflow: hidden !important;
      .customDataTable {
        float: left;
        min-width: auto;
        min-height: 65px;
        tr {
          display: flex;
          th:first-child,
          td:first-child {
            width: 20%;
          }
          th:nth-child(2),
          th:nth-child(3),
          td:nth-child(2),
          td:nth-child(3) {
            width: 40%;
          }
          td:only-child {
            width: 100%;
          }
        }
        thead {
          display: table;
          overflow: auto;
          width: 100%;
          border-bottom: 1px solid rgba(224, 224, 224, 1);
          tr {
            padding-right: 15px;
          }
          th {
            border: none;
            svg {
              display: none;
            }
          }
        }
        tbody {
          display: block;
          max-height: 350px;
          overflow: auto;
          width: 100%;
          tr span {
            //defect-209075
            display: inline-block;
            min-height: 24px;
          }
        }
        tbody:empty {
          min-height: 60px;
        }
        .MuiTableCell-root {
          padding: 5px 2%; //defect-209075
        }
        td.MuiTableCell-root {
          padding: 7px 0 7px 4%;
        }
        tr:last-child > * {
          border-bottom: 0 none;
        }
        tr input {
          border: solid 1px #707070;
          @include border-radius(3px);
          &:focus-visible {
            @extend .outline-0;
          }
        }
      }
    }
    .customDataTable .MuiTableHead-root {
      background-color: #fff !important;
    }
    .MuiTableSortLabel-root,
    .MuiTableSortLabel-root:hover,
    .MuiTableSortLabel-root.MuiTableSortLabel-active {
      color: $PrimaryColor !important;
    }
    &.tbl-no-border {
      tbody,
      tfoot {
        .MuiTableCell-root {
          border: none;
        }
      }
    }
  }
  .claims-table-records .table-wrapper .customDataTable tbody,
  .clm-inquiry-exceptionData .table-wrapper .customDataTable tbody {
    max-height: 348px !important; //defect-209075
  }
  .excp-tcn-devider {
    //defect-209075
    padding-bottom: 4px;
    border-bottom: solid 1px #e0e0e0;
    margin-bottom: 4px;
  }
  .view-more-btn {
    box-shadow: none !important;
    color: $PrimaryColor !important;
    font-size: 12px !important;
    text-transform: capitalize;
    @extend .no-bdr;
    &.focused-button {
      @extend .no-bdr;
    }
  }
  .exp-coll-btn {
    box-shadow: none !important;
    color: #0047ba !important;
    font-size: 13px !important;
    text-transform: capitalize;
  }
  .sa-exception-tbl {
    .table-wrapper {
      margin: 0 !important;
    }
    th {
      svg {
        display: inline-block !important;
        font-size: 13px;
      }
    }
  }
  /* *--* */
  /*--Custom Tabber Starts Here--*/
  .custom-tabber {
    & .MuiTab-wrapper {
      font-weight: 600;
      line-height: 1;
      font-size: 1.25em;
      letter-spacing: normal;
    }
    .Mui-selected {
      color: $PrimaryColor !important;
      font-weight: 700 !important;
      padding: 6px 12px !important;
    }
    .MuiTab-root {
      text-transform: capitalize !important;
      min-width: 120px !important;
      padding: 6px 12px !important;
      &:focus {
        outline: none;
        border: none;
      }
    }
    .MuiAppBar-colorDefault {
      background: none;
      box-shadow: none;
      font-weight: 600;
      padding: 0 12px;
      .MuiTabs-root {
        border-bottom: solid 1px #aaafb9;
      }
    }
  }
  .fit-tab-1 {
    .MuiTab-root {
      max-width: none;
    }
  }
  /*--Custom Tabber ends Here--*/
  
  .App-link {
    color: $SuccessColor;
  }
  .rdt_TableHeadRow {
    background-color: $PrimaryColor;
    color: white;
  }
  .rdt_TableHeadRow .sc-jTzLTM {
    color: white;
    font-weight: bold;
    font-size: 14px;
  }
  .hLrWNh,
  .inhAeF {
    color: #ffffff !important;
  }
  .gXlqCi {
    display: none !important;
  }
  /*--footer-nav-items--*/
  .footer-nav-items span:hover a {
    text-decoration: underline !important;
    color: $PrimaryColor !important;
  }
  .footer-nav-items {
    overflow: hidden;
  }
  .footer-nav-items span {
    line-height: 1;
    display: inline-block;
  }
  .footer-nav-items span a {
    border-right: 1px solid #0047ba;
    padding: 0 5px;
    color: #0047ba !important;
    font-size: 0.713em;
    font-weight: 600;
    text-decoration: none !important;
  }
  .footer-nav-items span:last-child a,
  .footer-nav-items span:last-child {
    border-right: 0;
  }
  /*--footer-nav-items-end--*/
  
  .MuiDrawer-paperAnchorDockedLeft {
    border-width: 2px;
  }
  .v-align-end {
    align-items: flex-end !important;
  }
  .tab-header,
  .page-header,
  .tab-holder,
  .tab-footer,
  .clear-both,
  .MuiCollapse-container {
    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }
  .tab-holder {
    padding: 0px;
    & > .MuiTypography-body1 {
      padding: 0 12px;
      @include border-radius(0px 0px 4px 4px);
    }
    .MuiBox-root {
      padding: 0px;
    }
  }
  .tab-holder.p-20,
  .wrap-tab-holder .tab-holder {
    padding: 0px 12px !important;
  }
  .tab-holder .makeStyles-container-154 {
    padding: 0px 24px;
  }
  
  .tabs-container,
  .flex-wrap-child > div:last-child,
  .makeStyles-containerFluid-12.container-fluid,
  .content-wrapper > div > .container-fluid {
    padding: 0px !important;
  }
  
  .MuiAppBar-colorPrimary {
    .MuiButtonBase-root {
      background-color: $PrimaryColor;
      opacity: 1;
      margin-right: 0.3rem;
      @include border-radius(4px 4px 0px 0px);
      font-weight: bold;
      .MuiTab-wrapper {
        text-transform: initial;
        font-size: 14px;
      }
      &:hover {
        background-color: $PrimaryColor !important;
        /* @include boxShadow(0px 0px 25px$PrimaryColor); */
        z-index: 2;
        @include animate-css(all 200ms ease-in);
        -webkit-transform: scale(1.12);
        -ms-transform: scale(1.12);
        -moz-transform: scale(1.12);
        transform: scale(1.12);
        border: 1px solid #ffffff;
      }
    }
  }
  .MuiAppBar-colorPrimary
    .MuiButtonBase-root.MuiTab-textColorInherit.Mui-selected {
    background-color: #ffffff !important;
    color: black;
    border: 2px solid $SecondaryColor;
    border-bottom-width: 0px !important;
  }
  .MuiAppBar-colorPrimary
    .MuiButtonBase-root.MuiTab-textColorInherit.Mui-selected:first-child:hover {
    border: 4px solid $SecondaryColor;
    margin-left: 7px !important;
    border-right-width: 2px !important;
    border-bottom-width: 0px !important;
    transform: scale(1.12);
  }
  .MuiAppBar-colorPrimary
    .MuiButtonBase-root.MuiTab-textColorInherit.Mui-selected:hover {
    border-top: 4px solid $SecondaryColor;
    @include border-radius(6px 6px 0px 0px);
  }
  .MuiTouchRipple-root,
  .Mui-selected {
    background-color: transparent !important;
    border-bottom-color: transparent !important;
  }
  .MuiTouchRipple-root,
  .Mui-selected {
    background-color: transparent !important;
    border-bottom-color: transparent !important;
  }
  .menu-close-button {
    top: 60px;
    @include border-radius(50% !important);
    padding: 6px !important;
    left: -27px !important;
  }
  
  .search-form,
  .flex-radio {
    display: flex;
    align-items: flex-start;
  }
  
  .MuiInputBase-adornedStart {
    .MuiInputAdornment-root {
      position: relative;
      //left: -3px;
    }
  }
  
  /**Side Navigation**/
  .navbar-blue-bg,
  .left-bottom-logo {
    &.bottom {
      position: relative;
      left: 0px;
      bottom: 0px;
      justify-content: center;
      min-height: auto;
      width: 100%;
      border-bottom-width: 0px !important;
      border-top: 2px solid #fff;
      padding: 6px 0px 0px;
      background-color: #f4f4f4;
      img {
        width: 160px;
        height: auto;
      }
      .sidemenu-heading {
        font-size: 14px;
      }
    }
  }
  .scollnavBar > div {
    overflow: initial !important;
  }
  .nav-list-container {
    height: calc(100% - 2px);
    overflow-y: auto;
  }
  
  .footer-text {
    font-size: 0.75em;
    text-align: center;
    color: #545955;
  }
  .menu-close-button .MuiSvgIcon-root {
    font-size: 24px !important;
    position: relative;
    left: 5px;
  }
  .activeNav {
    background-color: $PrimaryColor !important;
    color: #ffffff !important;
    @include border-radius(5px);
  }
  
  .side-menu-list {
    .MuiSvgIcon-root {
      color: #545955 !important;
      font-size: 19px;
    }
    .MuiTypography-root {
      color: #545955 !important;
      font-size: 0.95rem;
      a {
        text-decoration: none !important;
      }
    }
    .MuiListItemIcon-root {
      min-width: 45px;
    }
    .sideNav-Link {
      display: block;
      &:hover {
        background-color: #dadddc !important;
      }
    }
    .activeNavLi {
      a,
      a:hover {
        background-color: $PrimaryColor !important;
        @include border-radius(5px);
        span,
        div {
          color: #ffffff !important;
          text-decoration: none !important;
        }
      }
    }
    & > .MuiListItem-gutters,
    .dashBoard {
      padding-left: 6px !important;
      padding-right: 6px !important;
      min-height: 35px;
    }
    & & .menu-level-3 {
      .sideNav-Link:hover {
        background-color: #f4f4f4 !important;
        @include border-radius(5px);
      }
      .sideNav-Link {
        padding-left: 40px !important;
        padding-right: 10px !important;
        &:hover,
        &:hover * {
          color: $PrimaryColor !important;
          text-decoration: none;
        }
        .MuiListItemText-root {
          padding-right: 0 !important;
        }
      }
      .sideNav-Link:after {
        left: 32px;
      }
    }
    & > .MuiListItem-gutters,
    &.dashBoard {
      .material-icons {
        position: relative;
        left: -3px;
        color: #aaafb9;
      }
    }
    & > .MuiListItem-gutters:hover *,
    .dashBoard:hover * {
      text-decoration: none;
    }
    &:list-child {
      height: 100%;
    }
    .MuiCollapse-wrapperInner {
      margin-bottom: 3px;
      .sideNav-Link,
      .MuiListItem-button {
        padding-left: 35px !important;
        cursor: pointer;
        .MuiListItem-gutters {
          padding-left: 0 !important;
        }
        .MuiSvgIcon-root {
          font-size: 19px;
        }
        .MuiListItemIcon-root {
          min-width: 38px;
        }
        .MuiTypography-root {
          font-size: 0.9rem;
        }
        &:hover {
          .MuiSvgIcon-root,
          .material-icons {
            color: $PrimaryColor !important;
          }
        }
        &.activeNav {
          &:hover {
            .MuiSvgIcon-root,
            .MuiTypography-root,
            .material-icons {
              color: #ffffff !important;
            }
          }
        }
      }
    }
  }
  .MuiList-root.menu-level-3 {
    padding: 0 2px;
  }
  #sideNav {
    .dashBoard {
      padding-left: 6px !important;
      padding-right: 6px !important;
      min-height: 35px;
    }
    .side-menu-icon {
      width: 30px;
      text-align: center;
    }
    .fa {
      font-size: 20px;
      color: #aaafb9;
    }
  }
  
  .activeNav,
  .activeNav:hover {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding-left: 15px;
    @include border-radius(5px !important);
    position: relative;
  }
  
  .activeNav *,
  .activeNav:hover *,
  .side-menu-list .activeNav .MuiTypography-root,
  .side-menu-list.activeNav .MuiTypography-root {
    color: #ffffff !important;
  }
  .activeNav:after {
    content: '';
    position: absolute;
    right: 0px;
    top: 3px;
    height: calc(100% - 6px);
    width: 4px;
    background: #ff8700;
    @include border-radius(4px);
  }
  
  .MuiList-root {
    position: relative;
  }
  .MuiIcon-root {
    width: 1.3em !important;
    height: 1.3em !important;
    font-size: 1.7rem !important;
    text-align: center;
    line-height: 1.3 !important;
    text-indent: 0 !important;
  
    & & .sideNav-Link:after {
      content: '';
      position: absolute;
    }
  }
  .MuiExpansionPanel-root .MuiCollapse-container {
    @include animate-css(all 0.45s cubic-bezier(0, 0.175, 1));
  }
  .side-drawer,
  .MuiDrawer-paper,
  .main-content,
  .top-navbar {
    transition: none !important;
    transition-timing-function: none !important;
  }
  .clm-inquiry-tabData,
  .clm-inquiry-exceptionData {
    transition: width 150ms !important;
    transition-timing-function: cubic-bezier(0.1, 0.4) !important;
  }
  .left-bottom-brand {
    display: inline-block;
  }
  .leftNavOff {
    .main-content {
      width: calc(100% );
    }
    .side-drawer,
    .MuiDrawer-paper {
      width: 75px !important;
    }
    .side-drawer {
      overflow-y: auto;
    }
    .MuiDrawer-paper {
      .MuiListItemText-root,
      .MuiSvgIcon-root {
        display: none;
      }
    }
    .side-drawer .MuiPaper-root {
      height: calc(100% - 90px);
      padding: 0;
    }
    .left-bottom-logo {
      &.bottom {
        position: fixed;
        width: 75px;
        .poweredBy {
          display: none;
        }
        .left-bottom-brand {
          width: 45px;
          overflow: hidden;
          position: relative;
          height: 45px;
          img {
            position: absolute;
            top: 0;
            right: -5px;
          }
        }
      }
    }
    .activeNav {
      &:after {
        right: -17px;
      }
    }
    #sideNav {
      overflow: initial;
      overflow-y: auto;
      padding-right: 15px;
      .MuiCollapse-wrapper {
        display: none;
      }
    }
    .MuiList-padding,
    .db-space {
      padding-right: 2px !important;
    }
  }
  .menu-level-3 .MuiListItemText-root .MuiTypography-root {
    padding-left: 15px;
  }
  /**----**/
  
  .MuiListItemText-root {
    overflow-wrap: break-word;
    padding: 0px 8px !important;
    margin: 0 !important;
    cursor: pointer;
    a {
      text-decoration: none;
    }
  }
  
  .PrivateTabIndicator-colorSecondary-63 {
    background-color: transparent !important;
  }
  
  .sidemenu-heading {
    font-size: 14px;
    font-weight: bold;
    /*color: $PrimaryColor;*/
    width: 100%;
  }
  
  .navbar-blue-bg .MuiSvgIcon-root {
    color: #fff !important;
  }
  .MuiDrawer-paper {
    background-color: $PrimaryColor !important;
  }
  
  fieldset {
    border: 1px solid #aaafb9 !important;
    @include border-radius(4px);
  }
  .custom-legend {
    width: 100% !important;
    padding: 9px 20px !important;
    background-color: $PrimaryColor;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: bold;
    text-transform: uppercase;
    @include border-radius(4px 4px 0px 0px);
  }
  .MuiFormHelperText-root,
  .MuiInputLabel-root,
  .text-black-0 {
    color: #000000 !important;
  }
  .MuiList-root {
    margin-top: 2px !important;
  }
  .MuiListItem-root {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .MuiBox-root-254 {
    display: none !important;
    border-width: 0px !important;
  }
  
  .MuiButtonBase-root + .MuiCollapse-container {
    // max-height: 630px;  //defect-209075
    overflow: auto;
    overflow: -moz-scrollbars-none; /* Firefox 64 */
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }
  
  .MuiButtonBase-root + .MuiCollapse-container .MuiTypography-body1 {
    border-width: 0px !important;
  }
  .MuiButtonBase-root + .MuiCollapse-container::-webkit-scrollbar {
    width: 2px;
    display: none;
  }
  .no-scrollbar::-webkit-scrollbar {
    width: 2px;
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
  }
  
  .MuiPickersToolbar-toolbar {
    background-color: $PrimaryColor !important;
  }
  .MuiPickersDay-daySelected {
    background-color: $PrimaryColor !important;
    color: $whiteColor !important;
    &:hover {
      background-color: #3f51b5 !important;
    }
  }
  .form-wrapper,
  .flex-wrap,
  .form-wrapper.sub-radio-group .sub-radio {
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-flow: row wrap;
    flex-wrap: wrap;
    .form-wrapper {
      width: 100%;
    }
  }
  .form-wrapper.vertical-wrapper {
    flex-direction: column;
  }
  
  .mui-custom-form.with-select {
    .MuiTextField-root {
      width: 200px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .mui-custom-form.with-date {
    .MuiTextField-root {
      width: 146px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .mui-custom-form.with-textarea {
    .form-control {
      font-size: 16px;
      color: #000;
    }
  }
  .mui-custom-form.with-icon,
  .form-no-label .form-wrapper .mui-custom-form {
    .MuiInput-root {
      margin-top: 0px;
    }
    .small-label {
      margin-bottom: 9px;
    }
  }
  
  /*** Styling Form Elements end ***/
  
  /*** Styling Data Table start ***/
  .table-wrapper {
    width: 100% !important;
    overflow-x: auto !important;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    // margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 !important;
    @include border-radius(4px 4px 0 0);
  }
  .table-wrapper-2,
  .tabs-wrapper-2 {
    margin: 15px 15px 15px 20px;
  }
  .customDataTable {
    //min-width: 750px;
    min-height: 115px;
    .sa-exception-tbl & {
      min-height: 100px;
    }
    thead {
      th {
        font-size: 0.92rem !important;
        .MuiSvgIcon-root {
          opacity: 0;
          margin: 0;
        }
        &:hover {
          .MuiSvgIcon-root {
            opacity: 1;
          }
        }
        .MuiCheckbox-root {
          .MuiSvgIcon-root {
            color: #fff !important;
            opacity: 1;
          }
        }
      }
    }
    .MuiCheckbox-root {
      padding: 0px;
    }
    .MuiTableCell-root,
    .MuiTableRow-footer {
      padding: 5px 8px;
    }
    .MuiTableHead-root {
      background-color: $PrimaryColor;
      .MuiTableSortLabel-root {
        color: #fff;
        font-weight: 600;
        white-space: nowrap;
        &.MuiTableSortLabel-active {
          .MuiSvgIcon-root {
            color: #fff !important;
          }
        }
      }
    }
    .table-pagination {
      width: 35px;
      min-width: 35px;
      max-width: 35px;
      span {
        width: 25px;
        height: 25px;
        display: inline-block;
        @include border-radius(50%);
        background-color: #545955;
        text-align: center;
        color: #fff;
        line-height: 25px;
      }
    }
    .MuiTableCell-paddingCheckbox {
      width: 35px !important;
      min-width: 35px !important;
      max-width: 35px !important;
      padding: 5px 2px 5px 8px !important;
    }
    .MuiTableFooter-root {
      .MuiIconButton-root {
        padding: 5px 2px;
      }
    }
    .MuiTablePagination-toolbar {
      min-height: 40px !important;
      min-width: 345px;
    }
    .MuiTableFooter-root .MuiIconButton-root:focus {
      outline: none;
      background-color: #dadddc !important;
    }
  }
  .MuiTableSortLabel-icon {
    font-size: 15px;
  }
  .set-tbl-Space {
    th {
      max-width: 220px;
      .MuiTableSortLabel-root {
        white-space: pre-wrap !important;
      }
    }
  }
  .wip {
    margin-top: 20%;
    margin-left: 35%;
    color: #bfa98e;
  }
  a,
  .cndt-link,
  .cndt-link a,
  table tr td a {
    color: $PrimaryColor ;
    text-decoration: underline ;
    &:hover{
      color: $PrimaryColor ;
      text-decoration: underline ;
    }
  }
  .customDataTable {
    .MuiTableRow-root {
      a {
        display: contents;
        width: 100%;
        text-decoration: none !important;
      }
    }
    .has-link {
      padding: 0px;
      a {
        padding: 7px 10px;
        color: $PrimaryColor !important;
        cursor: pointer;
        display: inline-block;
        width: 100%;
        height: 100%;
        text-decoration: underline !important;
        .clm-inquiry-exceptionData & {
          padding: 0 !important;
        }
      }
    }
  }
  .th-brd-r {
    border-right: solid 1px #e0e0e0;
  }
  table .MuiSvgIcon-root {
    .cndt-col-6 & {
      width: 0.8em;
    }
  }
  /*** Styling Data Table end ***/
  
  /*** Latest Styles For  Input  start ***/
  .mui-custom-form.input-xs {
    .MuiInput-input {
      width: 60px;
    }
    .MuiFormHelperText-root {
      max-width: 60px;
    }
  }
  .mui-custom-form.input-416 {
    .MuiInput-input {
      width: 416px;
    }
    .MuiFormHelperText-root {
      max-width: 416px;
    }
  }
  .mui-custom-form.input-146 {
    .MuiInput-input {
      width: 146px;
    }
    .MuiFormHelperText-root {
      max-width: 146px;
    }
  }
  .mui-custom-form.input-226 {
    .MuiInput-input {
      width: 226px;
    }
    .MuiFormHelperText-root {
      max-width: 226px;
    }
  }
  .mui-custom-form.input-sm {
    .MuiInput-input {
      width: 100px;
    }
    .MuiFormHelperText-root {
      max-width: 100px;
    }
  }
  
  .mui-custom-form.input-md {
    .MuiInput-input {
      width: 190px;
    }
    .MuiFormHelperText-root {
      max-width: none;
    }
  }
  .mui-custom-form.input-xl {
    .MuiInput-input {
      width: 270px;
    }
    .MuiFormHelperText-root {
      max-width: 270px;
    }
  }
  .mui-custom-form.input-xll.with-select {
    .MuiTextField-root {
      width: 350px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .mui-custom-form.input-xxl {
    .MuiInput-input {
      width: 470px;
    }
    .MuiFormHelperText-root {
      max-width: 470px;
    }
  }
  .mui-custom-form.input-xxxl {
    width: 1000px !important;
  }
  .mui-custom-form.input-mx {
    .MuiInput-input,
    .MuiFormControl-root {
      width: 100%;
    }
  }
  .mui-custom-form.input-xs.with-select {
    .MuiTextField-root {
      width: 60px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .mui-custom-form.input-146.with-select {
    .MuiTextField-root {
      width: 146px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .mui-custom-form.input-226.with-select {
    .MuiTextField-root {
      width: 226px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .mui-custom-form.input-sm.with-select {
    .MuiTextField-root {
      width: 100px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .mui-custom-form.input-sm.with-icon {
    .MuiTextField-root,
    .MuiInput-root {
      width: 100px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .mui-custom-form.input-md.with-select {
    .MuiTextField-root {
      width: 190px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .mui-custom-form.input-md.with-icon {
    .MuiTextField-root,
    .MuiInput-root {
      width: 190px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .mui-custom-form.input-xl.with-select {
    .MuiTextField-root {
      width: 280px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .mui-custom-form.input-xxl.with-select {
    .MuiTextField-root {
      width: 470px;
      .MuiInput-input {
        width: 100%;
      }
    }
  }
  .custom-fieldset {
    padding: 0px 10px;
    legend {
      display: inline-block;
      width: auto;
      font-size: 15px !important;
      white-space: nowrap;
      color: $PrimaryColor !important;
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .mui-custom-form.input-md .MuiInput-input {
    width: 99% !important;
  }
  .mui-custom-form .MuiInput-input.MuiSelect-select {
    padding-right: 5px !important;
  }
  /*** Latest Styles For  Input  end ***/
  .alert.custom-alert {
    position: relative !important;
    top: 0;
    z-index: 9;
  }
  .alert-danger.custom-alert {
    background-color: lighten($ErrorColor, 55%) !important;
    border-color: lighten($ErrorColor, 50%) !important;
    li {
      @extend .danger-text;
    }
  }
  .alert-success.alert-danger.custom-alert {
    background-color: lighten($SuccessColor, 55%) !important;
    border-color: lighten($SuccessColor, 50%) !important;
    li {
      color: $SuccessColor;
    }
  }
  .MuiCheckbox-colorPrimary,
  .MuiIconButton-colorSecondary,
  table tr td {
    .MuiSvgIcon-root {
      color: $PrimaryColor !important;
    }
  }
  .th-min-130 tr th {
    min-width: 130px !important;
  }
  .Mui-disabled .MuiSvgIcon-root,
  .Mui-disabled .MuiTypography-root,
  table tr td .MuiIconButton-root.Mui-disabled .MuiSvgIcon-root,
  .sub-radio .Mui-disabled .MuiRadio-root.Mui-checked .MuiSvgIcon-root,
  .MuiRadio-root.Mui-disabled svg.MuiSvgIcon-root {
    color: $disabledColor !important;
    background-color: transparent !important;
  }
  .ml-80 {
    margin-left: 80px;
  }
  .ShowoidCheckBox {
    display: none;
  }
  
  .mdl-demo {
    margin-right: 15px;
    .dropdown {
      width: 100%;
    }
    .dropdown-trigger {
      display: block !important;
      max-height: 132px !important;
    }
    .dropdown-content {
      max-height: 220px;
      overflow-y: auto;
      width: 100%;
    }
  }
  .very-small-label {
    font-size: 11px !important;
  }
  .password-left-padding {
    padding-left: 20px;
  }
  
  /*************** Login CSS ************/
  .login-wrapper {
    padding: 15px;
    padding-bottom: 75px;
    .main-footer {
      width: 100%;
      left: 0px;
    }
  }
  .shadow-box {
    @include border-radius(2px);
    @include boxShadow(0px 10px 10px grey);
    height: 100%;
  }
  .login-head-text {
    color: #fff;
    padding: 12px;
    font-size: 15px;
    width: 100%;
    background-color: $PrimaryColor;
    margin-bottom: 0px;
  }
  .login-box {
    padding: 10px 30px;
    ul {
      padding-left: 0px;
      margin-bottom: 0px;
      li {
        padding: 10px 0px;
        line-height: 15px;
      }
      &.with-border {
        li {
          border-bottom: 1px solid grey;
          &:last-child {
            border-bottom-width: 0px;
          }
        }
      }
      &.with-links {
        a {
          text-decoration: none;
          color: $PrimaryColor;
          font-size: 0.3cm;
        }
      }
    }
  }
  .btn-login {
    border: 2px solid $PrimaryColor !important;
    width: 100%;
    margin: 0px 0px 5px 0px;
    color: $PrimaryColor;
    background-color: #fff;
    &:hover,
    &:active {
      color: #fff !important;
      background-color: $PrimaryColor;
    }
  }
  .submitButton {
    justify-content: center;
    small {
      color: $PrimaryColor;
      margin-top: 3px;
    }
  }
  .login-checkbox {
    margin-top: 5px;
    margin-bottom: 0px;
    .MuiButtonBase-root {
      padding-top: 5px;
    }
    .MuiTypography-root {
      font-size: 14px;
    }
  }
  .log-banner {
    display: flex;
    height: 100vh;
    img {
      object-fit: cover;
    }
  }
  .login-carousel {
    border: 1px solid #fff;
    @include border-radius(1px);
    @include boxShadow(0px 4px 16px 0px grey);
    height: 100%;
    padding: 0px !important;
    background-color: grey;
    .carousel,
    .carousel-inner,
    .carousel-item {
      height: 100%;
    }
    .carousel-item {
      position: relative;
      .dark-bg {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .t-and-c {
    .MuiButtonBase-root {
      padding: 4px 10px 5px;
    }
    .set-rd-pos {
      display: flex;
      align-items: center;
    }
  }
  .DRGtableEdit table tr {
    th:first-child {
      width: 5%;
    }
    th {
      min-width: 80px !important;
    }
    .MuiTableSortLabel-root {
      @media (min-width: 1201px) {
        white-space: normal !important;
      }
    }
  }
  //User Action Button
  
  .user-action-btn {
    text-align: left;
    min-width: 280px;
    .btn {
      background-color: transparent !important;
      border-width: 1px !important;
      color: #000 !important;
      font-weight: bold;
      @include boxShadow(none !important);
      padding: 8px !important;
      .MuiSvgIcon-root {
        width: 1.2em;
        height: 1.2em;
        left: -2px;
        @extend .set-top-m2;
      }
    }
    .dropdown-menu {
      margin: 0px;
      padding: 0px;
      @include border-radius(0rem 0rem 0.25rem 0.25rem);
      width: 100%;
      .dropdown-item {
        border-bottom: 1px solid #dadddc !important;
        background-color: transparent !important;
        padding: 0px;
        &:last-child {
          border-bottom-width: 0px;
        }
        a {
          text-decoration: none !important;
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 10px 15px;
          color: #000 !important;
          background-color: transparent !important;
          @include animate-css(all 0.2s linear);
          &:hover {
            background-color: #dadddc !important;
            padding: 10px 15px 10px 20px;
          }
        }
      }
    }
  }
  
  /* chandan 17-01-20 */
  .table-wwrappers {
    width: 1045px !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }
  
  .flex-wrappers {
    display: flex;
    margin: 0px 0px 15px 30px;
  }
  
  .flex-header {
    background: #ccc;
    padding: 5px 170px 5px 5px;
    border-right: 1px solid #fff;
  }
  
  .flex-content {
    color: #fff;
    padding: 5px 5px;
  }
  .header-space {
    padding: 5px 15px;
    overflow: hidden;
  }
  
  .flex-wrapper-item {
    width: 50%;
  }
  
  .table-wrapper-holder .table-wrapper,
  .tbl-wrap .table-wrapper {
    margin-bottom: 0 !important;
  }
  /* two column css 24-01-20 */
  .cndt-columns {
    margin: 0 16px 20px 16px;
  }
  .cndt-row,
  .cndt-columns,
  .form-inner-block {
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-flow: row wrap;
    flex-wrap: wrap;
  }
  
  .cndt-row {
    margin-right: -5px;
    margin-left: -5px;
    max-width: none;
  }
  .cndt-row > .cndt-col,
  .cndt-row > [class*='cndt-col-'] {
    position: relative;
    width: 100%;
  }
  .cndt-row > .cndt-col,
  .cndt-row > [class*='cndt-col-'],
  .cndt-row-wrapper {
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .cndt-col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  
  .cndt-col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  
  .cndt-col-3,
  .DB-Links-Panel,
  .columns-data .md-block-3cols {
    -ms-flex: 0 0 23%;
    flex: 0 0 23%;
    max-width: 23%;
    margin-right: 5px;
  }
  
  .cndt-col-4,
  .cndt-3-col {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  
  .cndt-col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  
  .cndt-col-6,
  .cndt-2-col,
  .columns-data .field-md {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .cndt-col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  
  .cndt-col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  
  .cndt-col-9,
  .field-lg,
  .DB-Main-Panel {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  
  .cndt-col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  
  .cndt-col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  
  .cndt-col-12,
  .field-xl,
  .columns-data .field-xl {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .tab-wrapper {
    padding: 8px;
    padding-right: 45px;
  }
  
  .cndt--col + .cndt--col {
    border-left: 2px solid #fff;
  }
  
  .cndt-col-5 input[type='text'] {
    padding: 6px 0 7px !important;
  }
  /* --- */
  .mt-sub-radio {
    min-height: 40px;
  }
  .set-flex-label {
    .MuiFormLabel-root {
      height: 45px;
      display: flex;
      -webkit-display: flex;
      white-space: normal;
      line-height: 1.4;
    }
    .MuiInputBase-root {
      margin-top: 60px !important;
    }
  }
  .cndt-row .mui-custom-form.with-select .MuiInput-root,
  .input-mt-0 .input-md,
  .mui-clr-mrgn .mui-custom-form,
  .set-no-margin .MuiInput-root,
  .search-form .makeStyles-container2-151,
  .no-mt .MuiInput-root,
  .textfield-margin-zero .MuiInput-root,
  .bill-prov-type .MuiInput-root,
  .page-header + div,
  .input-mt-0.set-no-margin {
    margin-top: 0 !important;
  }
  
  .form-wrapper .mui-custom-form .wrap-label-margin .MuiInput-root {
    margin-top: 18px !important;
  }
  .cndt-columns {
    background: #fff;
  }
  .sr-criteria-w1 {
    width: 160px !important;
  }
  .sr-criteria-w2 {
    width: calc(100% - 165px) !important;
  }
  .sr-form-field {
    float: left;
    min-width: 120px;
    max-width: 185px;
    margin-right: 15px;
    width: 33%;
    margin-bottom: 15px;
  }
  .sr-field-2 {
    min-width: 280px !important;
    max-width: 280px !important;
    width: 60% !important;
  }
  .sr-form-field1 {
    display: inline-flex;
    label {
      margin: 10px 5px 0 0;
    }
  }
  .set-Left,
  .sub-radio .MuiFormGroup-row,
  .setLeft .MuiCheckbox-root,
  .MuiPaper-root .MuiCardHeader-root .MuiCheckbox-root {
    margin-left: -9px;
  }
  .mui-custom-form .MuiInputLabel-shrink {
    display: inline-block;
    margin: auto;
    transform: translate(0, 1.5px) scale(0.79);
    transform-origin: top left;
  }
  .MuiTypography-body1 .tabsInner2 {
    padding: 0 !important;
  }
  .sub-h2 {
    font-size: 14px;
  }
  .radio-100 {
    width: calc(100% - 20px) !important;
  }
  .inline-title-2,
  .primary-text,
  .mui-custom-form .cndt-label,
  .MuiRadio-root.Mui-checked .MuiSvgIcon-root {
    color: $PrimaryColor !important;
  }
  .inline-title-2,
  .bold-600,
  .fw-600,
  .flex-wrap span small {
    font-weight: 600 !important;
  }
  .bold-500,
  .fw-500,
  .fw-500 *,
  .MuiExpansionPanelSummary-content .MuiTypography-root * {
    font-weight: 500 !important;
  }
  .columns-data .column-list-row .cndt-col-4 span:first-child,
  .view-data-value {
    //justify-content: flex-end;
    text-align: left !important;
  }
  .flex-block.columns-data,
  .form-wrapper .flex-block.columns-data {
    flex-wrap: wrap !important;
    -webkit-flex-wrap: wrap !important;
    -ms-flex-flow: row wrap !important;
    flex-wrap: wrap;
  }
  .form-wrapper .flex-block.columns-data {
    align-items: baseline;
    -webkit-align-items: baseline;
  }
  .inline-title {
    .text-left-imp &,
    .flex-block.columns-data & {
      text-align: left !important;
      vertical-align: bottom;
    }
  }
  .view-data-value,
  .inline-title,
  .columns-data .column-list-row [class*='cndt-col-'] > span:first-child,
  .md-block-3cols .primary-text,
  .columns-data.view-data-cols .md-block-3cols .view-data-label {
    display: inline-flex;
    padding-right: 8px;
    color: #545955 !important;
    //width: 55%;
    font-weight: normal !important;
    overflow: hidden;
  }
  .common-label {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500 !important;
    color: $PrimaryColor !important;
    justify-content: flex-start !important;
    -webkit-justify-content: flex-start;
  }
  .md-block-3cols,
  .form-view-data-cols {
    .view-data-label,
    .view-data-label *,
    .inline-title {
      @extend .common-label;
    }
    .MuiTypography-body1 {
      padding-top: 2px;
    }
  }
  .columns-data.view-data-cols .md-block-3cols .view-data-value,
  .columns-data.view-data-cols .md-block-3cols .view-data-value * {
    @extend .common-label;
  }
  .md-block-3cols,
  .form-view-data-cols {
    .view-data-label,
    .view-data-value,
    .inline-title {
      padding: 0.4rem 1rem 0.25rem 0 !important;
      width: 100%;
    }
  }
  .columns-data.view-data-cols .md-block-3cols .view-data-label,
  .MuiExpansionPanelSummary-content .MuiTypography-root * {
    font-family: inherit !important;
  }
  .primary-text {
    .MuiFormControlLabel-label {
      color: $PrimaryColor !important;
    }
  }
  .primary-text.fw-600:empty:after {
    content: '..';
    height: 2px;
    color: #ffcc00;
    visibility: hidden;
  }
  .columns-claim-data {
    font-size: 0.95rem;
    padding: 0;
    .cndt-row {
      justify-content: space-between;
      margin-top: -10px;
      [class*='cndt-col-'] {
        width: 33.333%;
        flex-basis: 33.333%;
        max-width: calc(33.333% - 10px);
      }
      [class*='col-'],
      [class*='col-md-'] .data-holder {
        padding: 10px;
        background: #f4f4f4;
        margin: 10px 5px 0px;
        @include border-radius(4px);
        .flex-grid-1 {
          padding: 3px 0 4px;
          font-size: 0.9em;
          font-weight: 600;
          color: #000000;
          .inline-title {
            width: auto;
            font-weight: normal;
          }
        }
      }
    }
  }
  .columns-claim-data [class*='col-md-'] {
    @media (max-width: 991px) {
      padding-bottom: 12px;
    }
    .data-holder {
      height: 100%;
    }
  }
  .columns-data {
    &.flex-block .md-block-3cols {
      margin-bottom: 1.3rem !important;
    }
    .flex-grid-1 {
      display: flex;
      & > span {
        width: 50%;
        flex-basis: 50%;
        -webkit-flex-wrap: wrap;
        -ms-flex-flow: row wrap;
        flex-wrap: wrap;
      }
    }
    * {
      font-size: 1em;
    }
    .inline-title {
      text-align: right;
      justify-content: flex-end;
    }
  }
  .column-list-row [class*='cndt-col-'] {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .clean-hdr {
    font-size: 1.18rem !important;
    font-weight: 300 !important;
    color: #000 !important;
  }
  .minMizeGap label {
    align-items: end !important;
    margin-bottom: 2px;
  }
  .flex-list {
    display: flex;
    -webkit-display: flex;
    padding-right: 5px;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    .inline-title {
      width: min-content;
    }
    .set-width {
      min-width: 55%;
    }
  }
  .claim-inquiry-form,
  .wrap-form-label {
    font-size: 12px;
    .mui-custom-form {
      .MuiInput-root,
      .fm-inline-btn {
        margin-top: 40px !important;
      }
    }
    .sub-radio {
      margin-top: 15px !important;
    }
    .sub-radio.mt-0 {
      margin-top: 0px !important;
    }
    .mui-custom-form.input-md {
      .MuiFormLabel-root {
        height: 40px;
        max-width: 100%;
        display: flex;
        font-size: 0.95rem !important;
        white-space: normal;
        align-items: flex-end;
        line-height: 1.3;
        transform: none !important;
        padding-bottom: 5px;
        margin: 0;
      }
    }
  }
  @media (max-width: 1024px) {
    .wrap-form-label-sm {
      font-size: 12px;
      .mui-custom-form {
        .MuiInput-root {
          margin-top: 40px !important;
        }
      }
  
      .mui-custom-form.input-md {
        .MuiFormLabel-root {
          height: 40px;
          max-width: 100%;
          display: flex;
          font-size: 0.95rem !important;
          white-space: normal;
          align-items: center;
          line-height: 1.3;
          transform: none !important;
          padding-bottom: 2px;
          margin: 0;
        }
      }
    }
  }
  .lbl-select-2-line1 {
    .MuiFormLabel-root {
      width: 180px !important;
      .MuiFormLabel-asterisk {
        top: calc(3% - 18px) !important;
      }
    }
  }
  .lbl-select-2-line2 {
    .MuiFormLabel-root {
      width: 150px;
      .MuiFormLabel-asterisk {
        top: calc(3% - 18px) !important;
      }
    }
  }
  .claim-inquiry-form
    .form-wrapper
    .custom-form-wrapp-inner
    .mui-custom-form.input-md
    .MuiFormLabel-root {
    white-space: nowrap;
  }
  .set-rd-pos {
    margin-left: -9px;
    label.text-black {
      position: relative;
      top: 2px;
      left: -3px;
      margin-right: 0 !important;
    }
  }
  .sm-inline-radio {
    @extend .set-top-m2;
    .MuiFormControlLabel-root {
      margin: 0 10px 0 0;
      .MuiRadio-root {
        padding: 1px;
        .MuiSvgIcon-root {
          width: 0.8em;
          height: 0.8em;
        }
      }
    }
  }
  .inline-radio-1 {
    .MuiIconButton-root {
      padding: 0 6px 0 0 !important;
      margin-left: -2px;
    }
    .label-ttl {
      position: relative;
      top: 2px;
    }
  }
  .MuiInputAdornment-root {
    p {
      font-size: 12px;
    }
    .MuiTypography-colorTextSecondary {
      color: rgba(0, 0, 0, 0.7) !important;
    }
  }
  .flex-wrap-child,
  .flex-spce-bet {
    display: flex;
    -webkit-display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-flow: row wrap;
    flex-wrap: wrap;
  }
  @media (max-width: 1024px) {
    .flex-wrap-child {
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
      & > div {
        padding-right: 20px;
      }
    }
  }
  .exceptions_view {
    .MuiExpansionPanelSummary-root,
    .MuiExpansionPanelSummary-root.Mui-expanded {
      min-height: 32px !important;
    }
    .MuiExpansionPanelSummary-content,
    .MuiExpansionPanelSummary-content .Mui-expanded {
      margin: 6px 0 !important;
    }
  }
  .span_filters,
  .exceptions_view {
    .collapsable-panel {
      .MuiExpansionPanelDetails-root {
        padding: 5px 8px !important;
      }
      .MuiExpansionPanelSummary-root {
        padding: 0px 10px !important;
        .MuiTypography-root {
          font-size: 13px !important;
          letter-spacing: 0.025rem;
        }
        .MuiIconButton-root {
          padding: 5px;
        }
      }
    }
  }
  .span_filters {
    .mui-custom-form {
      margin: 10px 0px;
      .MuiInput-root {
        padding: 4px 6px 2px;
        height: 35px;
        .MuiInputBase-input {
          height: 1em;
        }
        .MuiIconButton-root {
          padding: 5px 0;
        }
      }
    }
  }
  .availableinfo_list {
    padding-left: 25px;
    .cndt-icon {
      position: absolute;
      top: calc(50% - 9px);
      left: -1px;
      font-size: 20px !important;
      line-height: 1;
    }
  }
  .mpl-tabs .MuiTab-root,
  .max-wid-none {
    max-width: none !important;
  }
  /*-- Responsive SCSS starts here */
  @media screen and (max-width: 1200px) {
    .form-wrapper {
      padding: 8px 4px;
      .ligin-form & {
        padding: 8px 4px 20px;
      }
      .mui-custom-form,
      .custom-form-wrapp-inner {
        width: calc(33.33% - 20px) !important;
        max-width: calc(33.33% - 20px) !important;
      }
      .mui-custom-form.field-md {
        width: calc(66.33% - 20px) !important;
        max-width: calc(66.33% - 20px) !important;
        min-width: calc(66.33% - 20px) !important;
      }
    }
    .carousel-content .carousel-text h1 {
      font-size: 36px;
    }
    .column-list-row.rspc-col .cndt-col-3,
    .columns-data .md-block-3cols {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .column-list-row.rspc-col .cndt-col-4,
    .row-feilds.rspc-col .cndt-col-4,
    .columns-data .md-field-md {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .columns-data .md-field-lg {
      -ms-flex: 0 0 66.333333%;
      flex: 0 0 66.333333%;
      max-width: 66.333333%;
    }
    .columns-data .md-field-xl {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .columns-claim-data .cndt-row [class*='cndt-col-'] {
      max-width: calc(50% - 10px);
    }
    .CustomExpansion-panel {
      .MuiExpansionPanelSummary-root .MuiIconButton-root {
        padding: 0.72rem 0.9rem;
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1160px) {
    .form-wrapper {
      .mui-custom-form {
        &.md-field-md {
          width: calc(50% - 20px) !important;
          max-width: calc(50% - 20px) !important;
          min-width: calc(50% - 20px) !important;
          -ms-flex: 0 0 calc(50% - 20px) !important;
          flex: 0 0 calc(50% - 20px) !important;
        }
        &.field-lg,
        &.md-field-lg {
          width: calc(66.33% - 20px) !important;
          max-width: calc(66.33% - 20px) !important;
          -ms-flex: 0 0 calc(66.33% - 20px) !important;
          flex: 0 0 calc(66.33% - 20px) !important;
        }
      }
    }
  }
  
  @media screen and (max-width: 1024px) {
    .main-content {
      width: calc(100% - 75px) !important;
      padding: 0 10px;
      margin-left: 75px;
      .makeStyles-drawerHeader-8 {
        padding: 10px;
      }
      .content-wrapper {
        padding: 80px 10px 15px 10px;
      }
    }
    /*-- Left Navigation --*/
    .side-drawer {
      position: fixed;
      left: 0;
      height: 100%;
      display: flex;
      z-index: 111;
      background: #f4f4f4;
      width: 245px !important;
      @include boxShadow(0 3px 10px rgba(0, 0, 0, 0.3));
      .leftNavOff & {
        width: 75px !important;
      }
    }
    .MuiDrawer-paper {
      width: 245px !important;
      .MuiListItemText-root,
      .MuiSvgIcon-root {
        display: block !important;
        .leftNavOff & {
          display: none !important;
        }
      }
      .leftNavOff & {
        width: 75px !important;
      }
    }
    .top-navbar {
      width: calc(100% - 20px) !important;
    }
    .column-list-row.rspc-col .cndt-col-3 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .customDataTable .MuiTableCell-root {
      max-width: max-content !important;
    }
    .form-wrapper {
      .mui-custom-form,
      .custom-form-wrapp-inner {
        width: calc(33.33% - 20px) !important;
        max-width: calc(33.33% - 20px) !important;
      }
    }
  }
  @media only screen and (max-width: 1200px) and (min-width: 1025px) {
    .leftNavOff .tabs-container > div:not(.clm-inquiry-tabData) {
      .form-wrapper {
        .mui-custom-form,
        .custom-form-wrapp-inner {
          width: calc(25% - 20px) !important;
          max-width: calc(25% - 20px) !important;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .form-wrapper,
    .form-wrapper.form-3-column,
    .form-2-column,
    .form-1-column,
    .form-2-column-custom {
      .mui-custom-form,
      .custom-form-wrapp-inner,
      .mui-custom-form-custom {
        width: calc(50% - 20px) !important;
        max-width: calc(50% - 20px) !important;
      }
    }
    .xs-field-lg {
      width: calc(100% - 20px) !important;
      max-width: calc(100% - 20px) !important;
      -ms-flex: 0 0 calc(100% - 20px) !important;
      flex: 0 0 calc(100% - 20px) !important;
    }
    .form-wrapper {
      .mui-custom-form {
        &.field-md,
        &.field-lg,
        &.md-field-lg {
          @extend .xs-field-lg;
        }
      }
    }
    .clm-inquiry-tabData .form-wrapper .mui-custom-form,
    .clm-inquiry-tabData .form-wrapper .custom-form-wrapp-inner {
      @extend .xs-field-lg;
    }
    .columns-claim-data .cndt-row [class*='cndt-col-'] {
      width: calc(100% - 10px) !important;
      max-width: calc(100% - 10px) !important;
      -ms-flex: 0 0 calc(100% - 10px) !important;
      flex: 0 0 calc(100% - 10px) !important;
    }
  }
  @media screen and (max-width: 576px) {
    .form-wrapper,
    .clm-inquiry-tabData .form-wrapper,
    .form-wrapper.form-3-column,
    .form-2-column,
    .form-1-column,
    .form-2-column-custom {
      .mui-custom-form,
      .custom-form-wrapp-inner,
      .mui-custom-form-custom {
        width: calc(100% - 20px) !important;
        max-width: calc(100% - 20px) !important;
      }
    }
  }
  @media screen and (max-width: 680px) {
    .logo-text {
      display: none;
    }
  }
  
  @media screen and (min-width: 769px) {
    .sm-form-4cols {
      .mui-custom-form {
        width: calc(25% - 20px) !important;
        max-width: calc(25% - 20px) !important;
      }
    }
    .form-wrapper {
      .smd-field-md {
        width: calc(50% - 20px) !important;
        max-width: calc(50% - 20px) !important;
      }
    }
    .table-pagination {
      overflow: visible;
      .table-current-page {
        min-width: 70px;
      }
    }
  }
  .sm-break-div,
  .line-break-div,
  .md-break-div {
    visibility: hidden;
    height: 1px;
    clear: both;
    flex-basis: 100%;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .md-break-div {
      display: none;
    }
    .sm-break-div,
    .line-break-div {
      display: block !important;
    }
  }
  @media screen and (max-width: 992px) {
    .columns-data .md-block-3cols,
    .sm-field-md {
      -ms-flex: 0 0 50% !important;
      flex: 0 0 50% !important;
      max-width: 50% !important;
      width: 50% !important;
    }
    .form-wrapper .mui-custom-form.sm-field-md {
      width: calc(50% - 20px) !important;
      max-width: calc(50% - 20px) !important;
      -ms-flex: 0 0 calc(50% - 20px) !important;
      flex: 0 0 calc(50% - 20px) !important;
    }
    .sm-field-lg,
    .form-wrapper .mui-custom-form.sm-field-lg {
      width: calc(66.66% - 20px) !important;
      max-width: calc(66.66% - 20px) !important;
      -ms-flex: 0 0 calc(66.66% - 20px) !important;
      flex: 0 0 calc(66.66% - 20px) !important;
    }
    .columns-data .md-field-lg,
    .columns-data .md-field-md,
    .sm-field-xl,
    .DB-Main-Panel,
    .DB-Links-Panel {
      width: 100% !important;
      -ms-flex: 0 0 100% !important;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .form-wrapper .mui-custom-form.sm-field-xl {
      width: calc(100% - 20px) !important;
      max-width: calc(100% - 20px) !important;
      -ms-flex: 0 0 calc(100% - 20px) !important;
      flex: 0 0 calc(100% - 20px) !important;
    }
    .sm-mt-0,
    .set-flex-label .sm-mt-0 {
      margin-top: 0 !important;
    }
    .sm-hide,
    p.MuiTablePagination-caption {
      display: none !important;
    }
    .sm-flex-end {
      justify-content: flex-end;
      -webkit-justify-content: flex-end;
    }
  }
  @media screen and (max-width: 1680px) {
    .form-wrapper {
      .mui-custom-form.sr-criteria-w1 {
        width: 160px !important;
      }
      .mui-custom-form.sr-criteria-w2 {
        width: calc(100% - 165px) !important;
        max-width: calc(100% - 165px) !important;
      }
    }
  }
  
  .hide-tfoot tfoot,
  .hide-elm,
  .d-none,
  .hide-footer .print-footer,
  .hide-th-checkbox table tr th .MuiCheckbox-root {
    display: none;
  }
  .mute-elm,
  .fadeElm,
  .mute-label label,
  .mute-lbl {
    opacity: 0 !important;
    visibility: hidden;
  }
  .clean-gray-ttl {
    .MuiFormLabel-root {
      color: rgba(0, 0, 0, 0.75) !important;
      font-weight: normal;
      font-size: 1.31em !important;
    }
  }
  .no-bdr {
    border: none !important;
  }
  .no-bdr,
  .outline-0,
  button:focus {
    outline: none !important;
  }
  .edit-icon-1,
  .tab-header .sub-radio .MuiFormControlLabel-label {
    color: #545955;
  }
  /*-- Custom classes starts here */
  .font-18,
  .edit-icon-1 {
    font-size: 18px;
  }
  .text-black {
    color: #222222 !important;
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    letter-spacing: 0.00938em;
    line-height: 1.5;
  }
  .text-gray {
    color: #aaafb9 !important;
  }
  .txt-sntc-case {
    text-transform: lowercase;
  }
  .txt-sntc-case:first-letter {
    text-transform: uppercase;
  }
  .txt-caps {
    text-transform: capitalize !important;
  }
  .txt-case-none {
    text-transform: none !important;
  }
  #cust-label,
  .text-transform-none,
  .MuiInputLabel-animated,
  .cust-label label,
  .txt-trans-none,
  .text-no-transform,
  .txt-no-trans,
  .custom-tabber .txt-trans-none {
    text-transform: none !important;
    .asterisk-pos {
      top: calc(50% - 17px) !important;
      left: 1px !important;
    }
  }
  .txtDecNone {
    text-decoration: none !important;
  }
  .w-100,
  .activeNav a,
  .cndt-textArea,
  .form-wrapper.form-3-column .w-100,
  .block-elm,
  .md-block-3cols .primary-text {
    @extend .wid-100;
  }
  .min-width-55 {
    min-width: 55%;
  }
  .MuiTableCell-head.min-width-100 {
    min-width: 100px !important;
    max-width: 220px !important;
  }
  .height-auto,
  .tab-footer {
    height: auto !important;
  }
  .max-hgt-none,
  .billingRA .MuiCollapse-container {
    max-height: none !important;
  }
  .px-5px {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .ml-0,
  .inline-lable-ttl label,
  .tab-header .th-btnGroup .btn:first-child,
  .page-header .th-btnGroup .btn:first-child {
    margin-left: 0 !important;
  }
  .pr-2px,
  .set-span-r2 span {
    padding-right: 2px !important;
  }
  .pr-15px {
    padding-right: 15px;
  }
  .no-events,
  .no-events *,
  .inactive-check-tbl table tr .MuiCheckbox-root,
  .inactive-check-tbl table tr .MuiCheckbox-root * {
    pointer-events: none;
  }
  .MuiPopover-paper .MuiMenu-list .MuiListItem-root:not(.Mui-selected):hover {
    background-color: rgb(239, 239, 239);
    color: $PrimaryColor;
    cursor: pointer;
  }
  /*--- ** ---*/
  /*-- Custom alert css --*/

  
  .tab-error {
    border-bottom: solid 2px $ErrorColor !important;
  }
  
  /*-- Custom css --(need to change)*/
  .py-2 label,
  .py-2 span {
    font-size: 14px;
  }
  .py-2 label {
    font-weight: bold;
  }
  // .tabs-container.mar-15{border: 1px solid #dadddc;}
  /*---*/
  .temp-chng-r .sub-radio .MuiCheckbox-root {
    margin-left: 8px !important;
  }
  .temp-chng-r .text-black {
    font-weight: bold;
  }
  .temp-chng-btn {
    margin: 10px 26px 0px 0px !important;
  }
  .temp-chng-txtb .MuiFormControl-root {
    margin-right: 20px;
  }
  .no-padd .text-black {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: 0.8rem !important;
  }
  .no-padd .MuiIconButton-root {
    padding: 0 5px 0 0;
  }
  .no-padd .MuiIconButton-root,
  .no-padd.sub-radio .MuiButtonBase-root {
    padding: 0;
  }
  .no-padd.sub-radio .MuiCheckbox-root {
    margin: 0 !important;
  }
  .no-padd-chk .MuiButtonBase-root {
    margin-left: 0 !important;
  }
  .no-padd-chk .text-black,
  .mr-range .MuiFormControl-root {
    margin-right: 15px !important;
  }
  .heading-tmp {
    font-size: 14px;
    color: $PrimaryColor;
    font-weight: bold;
  }
  .bdr-none {
    border: none;
    margin-left: 10px;
    margin-right: 10px;
  }
  .py-2 .MuiRadio-colorSecondary.Mui-checked {
    color: $PrimaryColor;
  }
  
  .fld-label {
    font-weight: bold;
    color: $PrimaryColor;
  }
  .multi-select-area {
    .MuiPaper-root {
      border: 1px solid #ccc;
      .MuiCardHeader-title {
        font-weight: 600;
        color: $PrimaryColor;
        font-size: 1rem;
      }
      .MuiCardHeader-avatar {
        .MuiCheckbox-root {
          padding-top: 0px;
          padding-right: 0px;
          padding-left: 7px;
        }
      }
      .MuiCardHeader-content {
        padding-top: 4px;
      }
    }
    button {
      background-color: $PrimaryColor;
      .MuiButton-label {
        color: #dadddc;
      }
    }
    button.Mui-disabled {
      background-color: #fff;
      color: #fff;
    }
  }
  
  .hide-multi-check .MuiPaper-root .MuiCardHeader-avatar,
  .hide-multi-check .MuiPaper-root .MuiCardHeader-subheader {
    display: none;
  }
  .hide-multi-check .MuiPaper-root .MuiTypography-h5 {
    font-size: 0.875rem !important;
  }
  
  .btn-icon-only {
    padding: 4px 15px !important;
    .fa {
      font-size: 20px;
      margin-right: 0;
    }
  }
  
  .auditLogDialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 15px;
    background-color: $PrimaryColor;
    h2 {
      padding: 8px 15px;
      background-color: $PrimaryColor;
      color: #ffffff;
      font-size: 1.2rem;
      margin-bottom: 0;
    }
    .btn-close-overlay {
      color: #fff;
    }
  }
  
  .quick-links-sticky,
  .sticky-block {
    position: -webkit-sticky;
    position: sticky;
    top: 5px; //defect-209075
  }
  .quick-links {
    margin: 0 10px !important;
    max-height: calc(100vh - 225px);
    overflow-y: auto;
    a {
      color: #5797ff;
      padding-left: 0;
      padding-right: 0;
      border-bottom: 1px solid #e7e7e7;
      .MuiListItemText-root {
        padding: 3px 0 !important;
      }
    }
  }
  .collapsable-panel nav.quick-links {
    max-height: calc(100vh - 225px);
    // min-height: 150px; //defect-209075
  }
  .claim-btnarea-bdr {
    border-bottom: 1px solid #e2e2e2;
  }
  
  .table-pagination {
    min-width: 80px;
  }
  .txt-status {
    .MuiInputBase-input {
      width: 45px;
      padding: 2px 0 !important;
    }
  }
  .text-stat {
    color: #999999;
  }
  .form-wrapper.form-3-column .mui-custom-form.wdth17 {
    width: 17% !important;
  }
  .form-wrapper.form-3-column .mui-custom-form.wdth43 {
    width: 43% !important;
  }
  
  .wrap-th-header {
    .customDataTable .MuiTableHead-root .MuiTableSortLabel-root {
      white-space: normal;
    }
  }
  .inline-th .MuiTableSortLabel-root {
    display: inherit !important;
  }
  th.MuiTableCell-root {
    vertical-align: middle !important;
  }
  .break-td-data {
    .MuiTableCell-root {
      word-break: break-all;
    }
  }
  .textarea-with-counter {
    margin-bottom: 0 !important;
  }
  .bill-prov-type {
    .with-select {
      margin: 0 !important;
    }
  }
  .input-check-label .MuiTypography-root {
    margin: 2px 0 0 4px;
    font-weight: 600 !important;
    font-size: 0.9rem !important;
    color: $PrimaryColor;
  }
  .wrap-form-label .input-check-label {
    position: relative;
    top: 8px;
  }
  
  .frm-resolution {
    .brk-label {
      white-space: normal;
      width: 190px;
      line-height: 18px;
    }
    .mui-custom-form .MuiInput-root {
      margin-top: 0;
    }
    .align-bot {
      display: flex;
      align-items: flex-end;
    }
    .rmv-ml {
      margin-left: 0 !important;
    }
  }
  .inline-text-control {
    .mui-custom-form .MuiFormControl-root {
      width: 85% !important;
      .MuiInput-root {
        margin-top: 0 !important;
      }
    }
  }
  // Custom table using UI LI start
  .d-table {
    border: 1px solid #e0e0e0;
    float: left;
    width: 100%;
    border-radius: 5px;
  }
  
  .d-table ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  .d-table ul li a {
    color: $PrimaryColor !important;
    font-size: 13px;
  }
  
  .d-column li {
    padding: 5px 2px 5px 5px;
    //width: 33%;
    color: $PrimaryColor;
    font-weight: 600;
    font-size: 13px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .d-column li.ex-li-hd {
    width: 23%;
  }
  .d-column li.ex-code-hd {
    width: 38%;
  }
  .d-column li.ex-status-hd {
    width: 38%;
  }
  ul.d-row:hover {
    background: #f4f4f4;
  }
  .d-row li {
    padding: 4px 2px 4px 5px;
    display: inline-block;
    //width: 33%;
    border-bottom: 1px solid #e0e0e0;
    .button-underline {
      text-decoration: underline !important;
      font-weight: normal !important;
    }
  }
  .btn-underln {
    text-decoration: underline !important;
  }
  .d-row li.ex-li-lst {
    width: 23%;
  }
  .d-row li.ex-code-lst {
    width: 41%;
  }
  .d-row li.ex-status-lst {
    width: 35%;
  }
  
  .txtbox-stat {
    width: 35px;
    height: 20px;
    font-size: 0.88em;
  }
  .rec-scroll,
  .custom-form-feild .MuiInput-multiline textarea {
    min-height: 65px !important;
    max-height: 120px;
    overflow-y: auto;
  }
  .d-table ul:last-child li {
    border-bottom: 0px;
  }
  
  // Custom table using ul li end
  
  // print css start
  .page-title {
    padding: 5px 0;
    .page-heading {
      font-size: 1.35em;
      font-weight: 600;
      color: #000;
    }
  }
  .print-logo {
    display: flex;
    height: 64px;
    .poweredBy {
      padding-top: 38px;
    }
  }
  .print-footer {
    height: 0px;
    visibility: hidden;
    overflow: hidden;
    .nav-menu {
      a {
        border-right: 1px solid #0047ba;
        padding: 0 5px;
        color: #0047ba !important;
        font-size: 0.713em;
        font-weight: 600;
        text-decoration: none !important;
      }
      span:last-child a {
        border-right: 0;
      }
    }
  }
  
  @page {
    size: A4;
    margin: 2cm;
  }
  @media all {
    .hide-on-screen {
      display: none;
    }
  }
  
  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
    }
    .page-heading + .hide-on-print,
    .hide-on-screen {
      display: inherit !important;
    }
    .hide-on-print,
    .hide-print-footer,
    .left-bottom-logo {
      display: none !important;
    }
    .print-block {
      .print-footer {
        height: auto;
        visibility: visible;
        overflow: auto;
      }
      .footer-text {
        width: auto;
      }
    }
    .para-truncate table tr td.MuiTableCell-root {
      //flex: 1;
      white-space: normal !important;
      overflow: auto;
      text-overflow: unset !important;
    }
    .hide-show-tbl-row {
      .table-wrapper {
        .MuiTableBody-root .MuiTableRow-root.MuiTableRow-hover {
          display: none;
        }
        .MuiTableBody-root .MuiTableRow-root.MuiTableRow-hover.Mui-selected {
          display: table-row;
        }
      }
    }
    .rquired-header + .tab-body,
    .page-header + .tab-body,
    .rquired-header + .tab-holder > .tab-body,
    div[class*='-header'] + .custom-hr + .tab-body {
      border: solid 1px #ececec;
    }
    .main-content {
      width: 100%;
      padding: 10px 20px 0;
    }
    .content-wrapper {
      min-height: auto !important;
      padding: 80px 0 15px !important;
    }
    .top-navbar {
      width: calc(100% - 40px) !important;
    }
    .MuiTypography-root .tabs-container .customDataTable, 
    .tabs-container .MuiTypography-root .customDataTable {
      thead .MuiTableCell-root, 
      tbody .MuiTableCell-root {
        @include PrintTableShrink;
        &span, 
        &.customDataTable .MuiTableCell-paddingCheckbox {
          @include PrintTableShrink;
        }
      }
    }  
  }
  .width-88 {
    width: 88%;
  }
  .grayTitle {
    color: #545955;
  }
  .font-weight-semi-bold {
    font-weight: 600;
  }
  .nameInitialBadge {
    @include border-radius(50%);
    width: 33px;
    height: 33px;
    text-align: center;
    display: inline-block;
    line-height: 33px;
    color: white;
    margin-right: 1em;
    margin-bottom: 1em;
    position: relative;
    top: 5px;
    font-weight: 600;
  }
  .MuiTableRow-root {
    &:nth-child(1n + 0) {
      .nameInitialBadge {
        background-color: #087a73;
      }
    }
    &:nth-child(2n + 0) {
      .nameInitialBadge {
        background-color: #396ec4;
      }
    }
    &:nth-child(3n + 0) {
      .nameInitialBadge {
        background-color: #663a36;
      }
    }
    &:nth-child(4n + 0) {
      .nameInitialBadge {
        background-color: #41454d;
      }
    }
    &:nth-child(5n + 0) {
      .nameInitialBadge {
        background-color: #a3635a;
      }
    }
    &:nth-child(6n + 0) {
      .nameInitialBadge {
        background-color: #7345a1;
      }
    }
    &:nth-child(7n + 0) {
      .nameInitialBadge {
        background-color: #274463;
      }
    }
    &:nth-child(8n + 0) {
      .nameInitialBadge {
        background-color: #1a7e77;
      }
    }
    &:nth-child(9n + 0) {
      .nameInitialBadge {
        background-color: #a33e30;
      }
    }
    &:nth-child(10n + 0) {
      .nameInitialBadge {
        background-color: #3c4c69;
      }
    }
  }
  
  .tooltip-heading {
    @extend .fw-600;
  }
  .tooltip-desc,
  .MuiInput-root.disabled-view-data a,
  .tooltip-heading {
    color: #000;
    font-size: 12px;
    letter-spacing: 0.05rem;
  }
  .mui-custom-form.input-md.zip-ext-div {
    display: flex;
  }
  .mui-custom-form.input-md.zip-ext-div div.MuiTextField-root:last-child {
    margin-left: 4px;
  }
  #submitted-member {
    font-size: 18px;
    font-weight: 600;
    color: $PrimaryColor;
  }
  .mui-custom-form.col-lg-12 {
    padding-left: 0px !important;
  }
  .tick_right {
    float: right;
    padding: 10px;
  }
  
  .MuiExpansionPanel-root.collapsable-panel {
    overflow: hidden;
  }
  .collapsable-panel.Mui-expanded {
    overflow: unset !important;
  }
  
  .sa-letter-filter {
    border: 1px solid #dddddd;
    margin: 1.3%;
  }
  
  .sa-letter-display {
    padding: 7px;
    background-color: #f4f4f4;
    font-size: 1.1em !important;
    color: #274463 !important;
    font-weight: 700;
  }
  
  .sa-letter-display-span {
    white-space: nowrap;
    color: #274463 !important;
    font-size: 0.925em !important;
    font-weight: 700;
  }
  
  .tableNoWrap {
    table {
      .MuiTableHead-root {
        background-color: $PrimaryColor;
        .MuiTableSortLabel-root {
          white-space: pre-wrap !important;
        }
      }
    }
  }
  .sa-letter {
    .MuiFormControlLabel-label {
      white-space: nowrap;
      color: #274463 !important;
      font-weight: 700 !important;
    }
    .txt-time-format,
    .date-format {
      font-size: 0.85rem;
    }
  }
  .form-wrapper .flex-block {
    flex-wrap: nowrap !important;
  }
  .fixed_Exceptions {
    // position: fixed !important; //defect-209075
    top: 5px; //defect-209075
    // right: 25px; //defect-209075
    // z-index: 9; //defect-209075
  }
  .MuiInput-formControl.MuiInput-multiline {
    height: auto !important;
    min-height: 38px;
    padding-right: 0 !important;
  }
  .custom-input-select {
    .MuiInputLabel-root {
      font-size: 0.95rem !important;
      margin: 3px 0 6px;
    }
    .required-label {
      color: $ErrorColor !important;
      margin-right: 0;
      padding-right: 1px;
    }
    &.mui-custom-form .MuiFormLabel-asterisk {
      position: relative;
      //left: -1px;
      padding-right: 1px;
    }
  }
  .wrap-form-label {
    label.Mui-required .MuiFormLabel-asterisk {
      left: -2px;
      top: 0px;
    }
    .custom-input-select.mui-custom-form .MuiFormLabel-asterisk {
      padding-right: 2px;
    }
  }
  .custom-input-select,
  .wrap-form-label .custom-input-select {
    .MuiInput-root {
      margin-top: 0 !important;
      width: 100% !important;
      padding-left: 0;
    }
  }
  .label-space-1 .MuiInput-root {
    margin-top: 1.5rem !important;
  }
  
  .MuiNativeSelect-select {
    padding-left: 10px !important;
  }
  .scrollTodiv {
    position: relative;
    top: -120px;
    height: 0px;
    visibility: hidden;
  }
  //.btn:focus{
  //  border: 2px dotted #cc0000!important;
  //}
  .cndt-textArea {
    resize: vertical !important;
    @include border-radius(4px);
    border-color: #707070 !important;
    padding: 10px;
  }
  .focused-input,
  .autoFocus:focus,
  .cndt-textArea:focus,
  .cndt-textArea:focus-visible {
    border: solid 1px $PrimaryColor !important;
    @include boxShadow(0 0 8px rgba(0, 0, 0, 0.3) !important);
    outline: none;
  }
  .focused-button {
    border: solid 1px orange !important;
    @include boxShadow(0 0 8px rgba(0, 0, 0, 0.3));
  }
  
  .para-truncate table tr td.MuiTableCell-root {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .hidden-inpts {
    display: none;
  }
  .opacity-trans {
    @include animate-css(opacity 0.3s linear);
  }
  /* POC
  .btn-add-new{
    background-color: #0047BA!important;
    color: #fff!important;
    border-color: #0047BA!important;
    &:hover, &:focus {
      background-color: #0a50c1 !important;
      color: #fff!important;
    }
  }
  
  .btn-search-new{
    background-color: #274463!important;
    color: #fff!important;
    border-color: #274463!important;
    &:hover, &:focus {
      background-color: #2f5b8a !important;
      color: #fff!important;
    }
  }
  
  .btn-save-new{
    background-color: #0047BA!important;
    color: #fff!important;
    border-color: #0047BA!important;
    &:hover, &:focus {
      background-color: #0a50c1 !important;
      color: #fff!important;
    }
  }
  .btn-delete-new{
    background-color: #fff!important;
    color: #A81F00!important;
    border-color: #A81F00!important;
    &:hover, &:focus {
      background-color: #A81F00 !important;
      color: #fff!important;
    }
  }
  .btn-audit-new{
    background-color: #fff!important;
    color: #005A64!important;
    border-color: #005A64!important;
    &:hover, &:focus {
      background-color: #005A64 !important;
      color: #fff!important;
    }
  }
  .btn-notes-new{
    background-color: #fff!important;
    color: #3C006E!important;
    border-color: #3C006E!important;
    &:hover, &:focus {
      background-color: #3C006E !important;
      color: #fff!important;
    }
  }
  .btn-cancel-new{
    background-color: #fff!important;
    color: #000!important;
    &:hover, &:focus {
      color: #fff!important;
    }
  }
  .btn-reset-new{
    background-color: #fff!important;
    color: #545955!important;
    border-color: #545955!important;
    &:hover, &:focus {
      background-color: #545955 !important;
      color: #fff!important;
    }
  }
  
  .btn-stay-new{
    background-color: #0047BA!important;
    color: #fff!important;
    border-color: #0047BA!important;
    &:hover, &:focus {
      background-color: #0a50c1 !important;
      color: #fff!important;
    }
  }
  
  .btn-continue-new{
    background-color: #fff!important;
    color: #000!important;
    border-color: #000!important;
    &:hover, &:focus {
      background-color: #fff!important;
      color: #000!important;
    }
  }
   */
  .Mui-disabled .MuiTypography-root {
    color: #545955 !important;
  }
  .collapsable-panel
    .MuiExpansionPanelSummary-root.Mui-focused.Mui-focusVisible
    .MuiTypography-root,
  .collapsable-panel
    .MuiExpansionPanelSummary-root.Mui-focused.Mui-focusVisible
    .MuiSvgIcon-root {
    color: #274463 !important;
  }
  
  .visuallyhidden,
  .scrollDiv {
    border: 0 none;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0 !important;
  }
  
  // Print-Help button icon css start
  .print-help-btn-wrapper {
    position: absolute;
    right: 13px;
    top: 20px;
    z-index: 1;
  }
  .top-navbar .MuiToolbar-regular {
    margin-right: 75px;
    .menu-close-button {
      display: none !important;
    }
  }
  
  .btn-print-new {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -ms-box-shadow: none !important;
    padding: 10px 12px !important;
    margin-right: 10px;
    background-color: #0a50c1 !important;
    border-radius: 50% 50% !important;
    height: 25px;
  }
  .btn-print-new:before {
    content: '\f02f';
    color: #fff;
    font-size: 1.1rem;
    left: 5px;
    bottom: -13px;
  }
  
  .btn-help-new {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -ms-box-shadow: none !important;
    padding: 0.71rem 0.85rem !important;
  }
  .btn-help-new:before {
    content: '\f059';
    color: #005a64;
    font-size: 2rem;
    left: 3px;
  }
  // Print-Help button icon css start
  .scrollDiv {
    top: -180px;
    left: 0;
    opacity: 0;
  }
  
  .top-navbar .makeStyles-hide-5 {
    display: none !important;
  }
  .MuiExpansionPanel-root.collapsable-panel {
    @extend .wid-100;
  }
  .MuiExpansionPanelSummary-root {
    width: 100%;
  }
  .custom-link {
    text-decoration: underline;
    cursor: pointer;
    color: #274463;
  }
  
  .rquired-header {
    background-color: #fff;
    color: #a81f00;
    font-size: 0.85em;
    font-weight: 600;
    padding: 0.5rem 0.6rem;
    border-top: 1px solid #ddd;
    margin: 0.35rem 0 0.57rem;
    line-height: 1;
    + div {
      margin-top: 0px !important;
    }
  }
  .inline-addons .mui-custom-form .MuiInput-root {
    margin-top: 0px !important;
  }
  .drawerHeader-im {
    display: flex;
    min-height: 56px;
    align-items: center;
    justify-content: flex-end;
  }
  .hide-lbl-2C table thead tr th:last-child {
    span.th-header,
    .MuiSvgIcon-root {
      display: none;
    }
  }
  .isLinkDisabled {
    color: currentColor;
    cursor: default !important;
    opacity: 0.5;
    text-decoration: none;
  }
  
  .lbl-align-left {
    .MuiInputLabel-shrink {
      margin: 0 !important;
    }
  }
  .capitation_table {
    table.customDataTable {
      //table-layout: fixed;
      .MuiCheckbox-root {
        padding: 0 !important;
      }
      .MuiTableCell-paddingCheckbox {
        padding: 5px 2px 5px 3px !important;
      }
      thead tr th:first-child {
        min-width: 30px !important;
        max-width: 30px !important;
      }
      thead tr th {
        &.MuiTableCell-root {
          padding: 5px 3px !important;
        }
        .th-header {
          font-size: 0.8rem;
        }
      }
    }
  }
  .flex-container-n {
    display: flex;
    flex-wrap: wrap;
  }
  
  .flex-item-n {
    padding: 5px 10px 5px 0px;
    flex: 40%;
  }
  
  .form-wrapper .mui-custom-form.mar-l-0 {
    margin-left: 0px !important;
  }
  .copy-address-from {
    width: 100% !important;
    .mui-custom-form {
      margin-left: 0px !important;
    }
  }
  .table-wrapper {
    .customDataTable {
      .MuiCheckbox-root {
        padding: 0px !important;
      }
    }
  }
  .na-txt {
    p {
      position: absolute;
      left: 200px;
      top: 30px;
      font-size: 0.95em;
    }
  }
  // Dynamic margin and padding start
  $spaceamounts: (
    5,
    7,
    10,
    15,
    20,
    25,
    30,
    35,
    40,
    45,
    50,
    75,
    100
  ); // Include the pixel amounts according to requirement.
  $sides: (top, bottom, left, right); // Leave this variable alone
  
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m-#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }
  
      .p-#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
  // Dynamic margin and padding end
  
  .customDataTable {
    span.th-header {
      text-align: left;
      display: inherit;
    }
  }
  .mar-bot-0 {
    margin-bottom: 0px !important;
  }
  
  // textarea {
  //   background: #f4f4f4 !important;
  //   border-color: #eee !important;
  //   font-size: 0.9em !important;
  //   padding: 7px 5px;
  //   width: 100%;
  //   @include border-radius(5px !important);
  // commented by @Sai 1st Nov 2022}
  
  .service-sec {
    .MuiGrid-container {
      flex-wrap: nowrap;
      .MuiGrid-item {
        width: 46%;
      }
    }
    .col-md-4 {
      .mui-custom-form {
        width: 100%;
      }
    }
  }
  .cust-sub-radio-lbl {
    .sub-radio {
      margin-top: 0;
    }
  }
  
  .lbl-3-line {
    label {
      padding-bottom: 10px !important;
    }
  }
  
  .multiselect-react-dropdown {
    .MuiFormLabel-root {
      font-size: 0.95rem !important;
      margin-top: 2px;
    }
    .multiselect-container {
      .searchWrapper {
        position: relative;
        height: 38px;
        overflow: hidden;
        border: 1px solid #707070;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 15px;
        input[type='text'] {
          font-size: 0.85rem;
        }
        .chip {
          padding: 0;
          color: #000;
          font-size: 0.8rem;
          background: none;
          display: inline;
          &:before {
            content: ', ';
            position: relative;
            left: -2px;
          }
          &:first-child::before {
            display: none;
          }
          img {
            display: none;
          }
        }
      }
    }
  }
  .multiselect-container {
    position: relative;
    &:after {
      font-family: 'FontAwesomeIcon';
      font-size: 1rem;
      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      content: '\f0d7';
      color: #707070;
      position: absolute;
      right: 5px;
      bottom: -4px;
      background: #fff;
    }
  }
  .multiSelectContainer li {
    font-size: 0.85rem !important;
    padding: 4px 10px !important;
    input[type='checkbox'] {
      float: left;
      margin-top: 4px;
    }
  }
  #search_input::placeholder {
    color: #000;
  }
  .chip + #search_input {
    opacity: 0;
    left: 0;
    position: absolute;
    top: -40px;
  }
  .cust-radio {
    .MuiFormControlLabel-root {
      margin-right: 7px;
      .MuiRadio-root {
        padding-right: 2px;
      }
    }
  }
  .cust-checkbox {
    padding-top: 20px;
    .MuiCheckbox-root {
      padding-top: 0px;
      margin-top: -7px;
    }
    .MuiFormControlLabel-label {
      font-size: 0.95rem;
    }
  }
  
  .lbl-2-line {
    .MuiFormLabel-root {
      width: 180px;
    }
    .MuiFormLabel-asterisk {
      top: calc(28% - 9px) !important;
    }
  }
  .lbl-select-2-line {
    .MuiFormLabel-root {
      width: 180px;
      .MuiFormLabel-asterisk {
        top: calc(3% - 9px) !important;
      }
    }
  }
  .lbl-select-2-line1 {
    .MuiFormLabel-root {
      width: 180px;
      .MuiFormLabel-asterisk {
        top: calc(3% - 18px) !important;
      }
    }
  }
  .lbl-select-2-line-160 {
    .MuiFormLabel-root {
      width: 160px;
      .MuiFormLabel-asterisk {
        top: calc(3% - 9px) !important;
      }
    }
  }
  .list-lbl-select-2-line {
    .MuiFormLabel-root {
      width: 180px;
    }
  }
  .table-checkbox-label {
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
  }
  .list-lbl-select-3-line {
    .MuiFormLabel-asterisk {
      top: calc(15% - 3px) !important;
    }
  }
  
  .list-lbl-select-208-line {
    .MuiFormLabel-asterisk {
      top: calc(3% - 18px) !important;
    }
  }
  
  .list-lbl-select-625-line {
    .MuiFormLabel-asterisk {
      top: calc(52% - 3px) !important;
    }
  }
  
  .cust-textarea-autosize {
    height: 37px !important;
    width: 100%;
    padding: 7px 5px !important;
  }
  
  textarea.disabled {
    background: #f4f4f4 !important;
    border-color: #eee !important;
  }
  .hide-dropdown-icon::after {
    content: none !important;
  }
  
  .lbl-3-lines {
    .MuiFormLabel-root {
      width: 180px;
      height: 30px !important;
    }
    .MuiFormLabel-asterisk {
      top: calc(28% - 20px) !important;
    }
  }
  
  .MuiInput-root.disabled-view-data {
    a {
      width: 100%;
      overflow: hidden;
    }
  }
  
  //Scrollable
  .MuiInput-root.disabled-view-data {
    a,
    .disabled-view-data-scroll {
      width: 90%;
      overflow: hidden;
      overflow-x: scroll;
      font-size: 12px;
      line-height: 22px;
    }
    &:focus {
      border: none !important;
    }
  }
  .MuiInput-root.disabled-view-data a::-webkit-scrollbar,
  .MuiInput-root.disabled-view-data
    .disabled-view-data-scroll::-webkit-scrollbar {
    display: none;
  }
  
  .disabled-view-data {
    svg {
      position: absolute;
      right: 0;
      bottom: 8px;
    }
  }
  
  .sitemap-container {
    a {
      color: #007bff !important;
      text-decoration: none !important;
      &:hover {
        color: #274463 !important;
        text-decoration: underline !important;
      }
    }
  }
  
  .exception-page {
    .row {
      padding-right: 25%;
      padding-left: 25%;
      .column {
        float: left;
        width: 33.33%;
        padding: 5px;
      }
    }
    a {
      color: #007bff !important;
      text-decoration: none !important;
      &:hover {
        color: #274463 !important;
        text-decoration: underline !important;
      }
    }
  }
  .build-version {
    color: #545955;
    font-size: 0.713em;
    padding-left: 12px;
    height: 25px;
    span a {
      color: #0047ba !important;
      text-decoration: none !important;
      cursor: pointer;
      font-weight: 700;
    }
  }
  .main-footer {
    .hide-print-footer {
      padding-top: 14px;
    }
  }
  // Temporarily added
  .print-block {
    height: 0;
  }
  .hideNativeSelect {
    svg.MuiSvgIcon-root.MuiNativeSelect-icon.Mui-disabled {
      display: none;
    }
  }
  
  .validAllign {
    width: 270px !important;
    display: block;
  }

  .btn-lock:before{
    content: "\f023";
    font-size: 1rem;
    }
  .eombAllign {
    width: 180px !important;
    border: 1px !important;
  }
  .textAllign {
    padding-left: 20px;
  }
  .minMizeGapLabel .MuiInputLabel-asterisk {
    top: -17px !important;
  }
  
  .form-wrapper .mui-custom-form.hiddenlabel {
    margin-top: 24px !important;
  }
  .MuiFormHelperText-root.required-label {
    color: $ErrorColor !important;
  }
  //text fields with no labels and occupy entire section- adding minimal margin
  .mui-no-margin.mui-custom-form {
    .MuiInput-root{
      margin-top: 5px !important;
    }
  }
  .hide-labels label {
    visibility:hidden;
  }
  .profile_card{
    .card-body{
      padding: 1.5rem 1.25rem;
    }
    
    .font12{
      font-size: 12px;
    }
  /*   .logOut a{
      text-decoration: none !important;
      color: #000 !important;
    } */
    .card-logo-border {
      position: absolute;
      box-sizing: border-box;
      top: 0px;
      left: 0px;
      border-radius: 50%;
      width: 90%;
      height: 100%;
      line-height: 1;
      border: 1px solid $iconColor;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      user-select: none;
      color: #274463;
    };
    .cardMain {
      display: inline-block;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      padding: 19px;
      font-size: x-large
    };
    .alignEnd {
      text-align: end;
    };
    .card_wordWrapper {
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: initial;
        white-space: nowrap;
      }
      .card_detail{
        display: inline-block;
        padding: 8px;
        width: 195px;
      }
  
      .cardLogo{
        width: 100px !important;
        font-weight: 600;
        height: 100px !important ;
        border-radius:   50% ;
        font-size : 38px ;
        margin-left: 0.6rem !important;
        //fontSize: xx-large'
      }
  }
  .width-full{
    width: 100%;
  }
  .card{
    background-color: red;
    color:red;
  }
  .profileIcon{
    span {
      box-sizing: border-box;
      top: 0px;
      left: 0px;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      line-height: 1;
      border: 1px solid $iconColor;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: center;
      user-select: none;
      font-size:  x-large;
      color: #274463;
    }
  
  }
.bg_myaccount{
  background-color: #fff !important;
  padding: 5px 10px !important;
}
  
  .myAccount {
    .list {
      width: 200px;
      height: 230px;
      background-color: #fff;
      overflow: auto;
    }
    .mar-Auto{
      margin: auto;
    }
    .border {
      border-style: groove;
      border-width: 1px;
    }
  
    .multi-select-area {
      .MuiPaper-root {
        border: none !important;
        .MuiCardHeader-title {
          font-weight: 600;
          color: $PrimaryColor;
          font-size: 1rem;
        }
        .MuiCardHeader-avatar {
          .MuiCheckbox-root {
            padding-top: 0px;
            padding-right: 0px;
          }
        }
        .MuiCardHeader-content {
          padding-top: 4px;
        }
      }
      button {
        background-color: $PrimaryColor;
        .MuiButton-label {
          color: #dadddc;
        }
      }
      button.Mui-disabled {
        background-color: #fff;
        color: #fff;
      }
    }
    
    .hide-multi-check .MuiPaper-root .MuiCardHeader-avatar,
    .hide-multi-check .MuiPaper-root .MuiCardHeader-subheader {
      display: none;
    }
    .hide-multi-check .MuiPaper-root .MuiTypography-h5 {
      font-size: 0.875rem !important;
    }
    /*-- CustomExpansion-panel ends here--*/
  .mui-custom-form .MuiGrid-item {
  padding-left: 0 !important;
  padding-right: 10px;
  min-width: 49%;
  .min-width-650 {
    min-width: 650px;
  }
  // width: 615px;
  .MuiList-root {
    //min-width: 240px;
    @media (min-width: 992px) {
      min-width: 100%;
      border: solid 1px #ccc;
    }
  
    padding-left: 0 !important;
    .MuiListItem-gutters {
      padding: 4px;
    }
    .MuiListItemIcon-root {
      min-width: 40px;
    }
  }
  }
  .MuiList-root {
  margin-top: 2px !important;
  }
  .MuiListItem-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  }
  .MuiBox-root-254 {
  display: none !important;
  border-width: 0px !important;
  }
  .MuiFormHelperText-root,
//  .MuiInputLabel-root,
  .text-black-0 {
  color: #000000 !important;
  }
  .MuiList-root {
  margin-top: 2px !important;
  }
  .MuiListItem-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  }
  .MuiBox-root-254 {
  display: none !important;
  border-width: 0px !important;
  }
  .MuiPaper-root .MuiCardHeader-root .MuiCheckbox-root {
  margin-left: -9px;
  }
  .btn-save:before, .btn-update:before {
  content: "\f023";
  font-size: 1rem;
  }
  .btn-success, .btn-save, .btn-add, .btn-update {
  background-color: #006e62 !important;
  border-color: #005A64 !important;
  &:hover, &:focus {
    background-color: #005A64 !important;
  }
  }
  .btn-save1:before, .btn-update:before {
  content: "\f00c";
  }
  .btn-success, .btn-save1, .btn-add, .btn-update {
  background-color: #006e62 !important;
  border-color: #005A64 !important;
  &:hover, &:focus {
    background-color: #005A64 !important;
  }
  }
  .btn-ic {
  padding: 8px 15px 8px 30px !important;
  }
  .btn-reset:before {
  content: "\f0e2";
  }
  .btn-transparent, .btn-delete, .btn-view, .btn-notes, .btn-reset, .btn-audit, .btn-filter-transparent {
  background-color: #fff !important;
  border:solid 1px $PrimaryColor !important;
  color: $PrimaryColor !important;
  &:hover, &:focus {
    background-color: #f8f8f8 !important;
  }
  }
  .btn-reset:before,
  .btn-reset-active:before {
  content: '\f0e2';
  }
  .btn-reset-active {
  background-color: $PrimaryColor !important;
  border-color: $PrimaryColor !important;
  &:hover,
  &:focus {
    background-color: #2f5b8a !important;
  }
  }
  .password-guide{  
    width: 100%;
    font-size: 10px;
    background-color: #f4f4f4;
    padding-top: 37px;
    display: flex;
    align-items: center;
    vertical-align: middle;
  
    li{
      font-size: 12px;
      color: #000000;
    }
  }
  .makeStyles-cardHeader-28 {
    padding: 8px 0px;
  }

  }
  .commonModuleClass .collapsable-panel .MuiExpansionPanel-root {
    border: none !important;
  }
  footer {
    a{
      border-right: 1px solid #0047ba;
      padding: 0 10px;
      color: #0047ba !important;
      font-size: 0.713em;
      font-weight: 600;
      text-decoration: none !important;
    }  
    a.lst-child{
      border-right: 0px solid #0047ba;
    }
  }
  .padl-25{
    padding-left: 25px !important;
  }
}


.table-wrapper .MuiCheckbox-root{
  justify-content: left !important;
  -webkit-justify-content: left !important;
}
.forgot-password{
  color: #0047ba !important;
}


.mfComponent .btn-home{
  padding: 8px 15px 8px 20px !important;
  color: #274463 !important;
  background-color: #fff!important;
  border: none!important;
  box-shadow: none!important;
  &:hover{
    color: #274463 !important;
    background-color: #fff!important;
  }
}
.btn-home:before {
  content: "\f015";
  left: 0px!important;
  font-size: 15px!important;
}

.iframe-container {
  div, span{
    width: 100%!important;
  }
}

.mfComponent .btn-print-n{
  background-color: #1bb4a0!important;
  border: 1px solid #1bb4a0!important;
  &:hover{
    background-color: #1bb4a0!important;
  }
}

.before-login{
  min-height: 80vh;
  .sso-page-header{
    padding: 0px 10px;
  }
  .login-container .container-fluid {
    min-height: calc(100vh - 185px);
  }
}
.tabs-container,
.flex-wrap-child > div:last-child,
.makeStyles-containerFluid-12.container-fluid,
.content-wrapper > div > .container-fluid {
  padding: 0px !important;
}
.log-bg {
  position: relative;
  background-color: $PrimaryColor;
  color: #fff;
}
.log-courosal {
  position: absolute;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  overflow: hidden;
}
.log-bg,
.log-courosal {
  @include border-radius(0px 12px 12px 0px);
}
.row {
  max-width: none;
}
.normallogin-container {
  height: 100%;
  padding: 20px 10px;
  // background-color: #eeeeee;
  min-height: calc(100vh - 197px);
  .container-fluid {
    // min-height: calc(100vh - 40px);
    div.white-bg {
      min-height: calc(100vh - 154px);
    }
  }
}
.form-wrapper-alignment {
  margin-left: 28px;
}
.password-guide{  
  width: 83%;
  font-size: 10px;
  background-color: #eeeeee;
  padding-top: 10px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}
/*Custom-styles*/
.white-bg,
.cndt-textArea {
  background-color: #ffffff !important;
}

.err-color {
  color: $ErrorColor;
}
span.err-red,
.alert-danger {
  color: $ErrorColor !important;
}
.alert.custom-alert {
  position: relative !important;
  top: 0;
  z-index: 9;
}
.alert-danger.custom-alert {
  background-color: lighten($ErrorColor, 55%) !important;
  border-color: lighten($ErrorColor, 50%) !important;
  li {
    @extend .danger-text !optional;
  }
}
.alert-success.alert-danger.custom-alert {
  background-color: lighten($SuccessColor, 55%) !important;
  border-color: lighten($SuccessColor, 50%) !important;
  li {
    color: $SuccessColor;
  }
}
// #current-passwprd-helper-text, #new-password-helper-text, #confirm-new-password-helper-text {
//   position: absolute;
//   bottom: -53px;
//   height: 51px;
// }
.border-red .Mui-error {
  border-color: red !important;
}


.sso-login-form {
  .MuiFormLabel-asterisk {
    position: absolute;
    left: -3px;
  }
  .MuiFormHelperText-root.required-label {
    color: $ErrorColor !important;
  }
  .custom-input-select {
    .MuiInputLabel-root {
      font-size: 0.95rem !important;
      margin: 3px 0 6px;
    }
    .required-label {
      color: $ErrorColor !important;
      margin-right: 0;
      padding-right: 1px;
    }
    &.mui-custom-form .MuiFormLabel-asterisk {
      position: absolute;
      left: -1px;
      padding-right: 1px;
    }
  }
  .label {
    @include border-radius(10px);
    padding: 2px 6px;
    &.label-danger {
      background-color: $ErrorColor;
    }
  }
  .MuiInput-underline.Mui-error:after {
    border-bottom-color: $ErrorColor !important;
  }
.MuiFormHelperText-root.Mui-error.Mui-required,
.Mui-red,
.Mui-red *,
.danger-text .MuiFormLabel-asterisk.MuiInputLabel-asterisk.Mui-error,
.Mui-required-lbl,
.MuiFormLabel-asterisk.MuiInputLabel-asterisk.Mui-error,
.required-label
{
  color: $ErrorColor !important;
}
.MuiFormLabel-root, .MuiCardHeader-root .MuiTypography-h5 {
  font-size: 1.22em !important;
  white-space: nowrap;
  color: $PrimaryColor !important;
  font-weight: 700;
  letter-spacing: 0.045em;
  -webkit-font-smoothing: antialiased;
  text-transform: capitalize;
  padding-left: 12px;
}
}

.loginForm {
  .MuiFormLabel-root.Mui-error
{
  color: rgba(0, 0, 0, 0.54);
}
.MuiFormLabel-root, .MuiCardHeader-root .MuiTypography-h5 {
  padding-left: 8px;
}
.MuiFormLabel-asterisk {
  position: absolute;
  left: -5px;
}
.MuiFormHelperText-root.required-label {
  color: $ErrorColor !important;
}
.MuiFormHelperText-root.Mui-error.Mui-required,
.Mui-red,
.Mui-red *,
.danger-text .MuiFormLabel-asterisk.MuiInputLabel-asterisk.Mui-error,
.Mui-required-lbl,
.required-label
{
  color: $ErrorColor !important;
}
.MuiFormLabel-asterisk.MuiInputLabel-asterisk.Mui-error{
  color: rgba(0, 0, 0, 0.54);
}

}

.custom-alert-box-inject {
  .btn {
    color: white !important;
    font-weight: 600 !important;
    font-size: 0.85rem !important;
    padding: 8px 15px !important;
    border-width: 1px !important;
    @include border-radius(5px !important);
    @include boxShadow(6px 3px 12px rgba(39, 68, 99, 0.3) !important);
    text-transform: uppercase;
    position: relative;
    text-decoration: none !important;
    .fa {
      font-size: 12px;
      margin-right: 5px;
    }
  }
  .btn-primary {
    background-color: $PrimaryColor !important;
    border-color: $PrimaryColor !important;
    &:hover, &:focus {
        background-color: #2f5b8a !important;
      }
  }
  .btn-transparent{
    background-color: #fff !important;
    border:solid 1px $PrimaryColor !important;
    color: $PrimaryColor !important;
    &:hover, &:focus {
      background-color: #f8f8f8 !important;
    }
}
.btn-success {
  background-color: #006e62 !important;
  border-color: #005A64 !important;
  &:hover, &:focus {
    background-color: #005A64 !important;
  }
}

  .MuiDialog-paper {
    min-width: 400px;
  }
  .MuiDialogContent-root {
    padding-top: 30px !important;
    .MuiTypography-body1 {
      font-size: 1.1rem;
      text-align: center;
      color: #222222;
    }
  }
  .MuiDialogActions-root {
    justify-content: center !important;
    padding-bottom: 30px !important;
    .btn {
        font-size: 0.92rem !important;
        padding: 7px 20px !important;
      }
  }
}
